import styled from 'styled-components';

export const UiBsLayout = styled.div`
	display: flex;
	max-width: 100vw;
	min-height: 100vh;
`;



export const UiContent = styled.div`
display: flex;
width: ${({ drawerWidth }) => `calc(100vw - ${drawerWidth}px) `};
flex-direction: column;
`;

export const UiDrawer = styled.nav`
width: ${({ width }) => `${width}px`};
flex-shrink: 0;
${({ theme: { media } }) => media.phone`
		width:auto
		`}
`;


import styled from 'styled-components';

export const UiClickableUpload = styled.div`
	display: inline-flex;
	cursor: pointer;
`;

export const UiUploadFileName = styled.div`
	display: flex;
	align-items: center;
	margin-top: 5px;
	padding: 3px;
	width: max-content;
	color: #000;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	> span {
		margin-right: 5px;
	}
`;

export const UiFileList = styled.div`
	display: flex;
	margin-top: 10px;
	padding: 8px;
	border: 1px solid #d0e0e2;
	border-radius: 5px;
	flex-direction: column;
`;

import styled from 'styled-components';

export const UiTeacherManagementForm = styled.div`
	display: flex;
	flex-direction: row !important;
	justify-content: center;
	flex-wrap: wrap;

	& > div {
		padding-top: 2rem;
	}

	& > form {
		width: 40%;
	}
`;

export const UiContent = styled.div`
	display: flex;

	> div {
		height: max-content;
	}
`;

export const FontBox = styled.div`
	display: block;

	& > div {
		padding: 0 1.5rem;
	}
`;


export const UiCoustomerButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

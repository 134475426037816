import styled from 'styled-components';

export const UiMemberTable = styled.div`
	display: block;
`;

export const UiButtonWrapper = styled.div`
	display: flex;

	& > button,
	& > div {
		margin-left: 1rem;
	}
`;

export const UiButtonSvg = styled.div`
	display: flex;
	align-items: center;

	& svg {
		margin-right: 5px;
		font-size: 1.7rem;
	}
`;

export const UiHomeworkLink = styled.div`
	font-weight: bold;
	text-decoration: underline;
	color: #00e;
	cursor: pointer;
`;

export const UiInviteClassCode = styled.div`
	display: flex;
	align-items: center;
`;

export const UiSvg = styled.div`
	line-height: 0;

	& svg {
		cursor: pointer;
		margin-top: 5px;
		margin-left: 5px;
		font-size: 2rem;
	}
`;

export const UiInviteClassCodeDate = styled.div`
	color: #8b90a0;
`;

export const UiHiddenInput = styled.div`
	height: 0;
	opacity: 0;
`;

export const UiSearchBox = styled.div`
	display: flex;
	align-items: center;

	> * + * {
		margin-left: 16px;
	}
`;

import styled from 'styled-components';

export const UiHeartbeatPacketBar = styled.div`
	display: flex;
	align-items: center;
`;


export const UiBar = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 1rem;
	background-color: #ccc;
`;

export const UiDot = styled.div`
	position: absolute;
	top: 50%;
	left: ${({ left }) => `${left}%`};
	display: inline-flex;
	font-size: 1.6rem;
	color: ${({ active }) => active ? '#00f' : '#f00'};
	transform: translateY(-50%);
`;

import styled from 'styled-components';

export const UiHomeGroupLayout = styled.div`
	display: flex;
	height: 100%;
	min-height: 100vh;
	background-color: #fff;
`;
export const UiHomeGroupContent = styled.div`
	display: flex;
	padding: ${'2rem 0 2rem'};
	padding-top: 0;

	/* ${({ theme: { media } }) => media.desktop`
      width:${({ theme }) => theme.mediaWidth.desktop}
      `}
	${({ theme: { media } }) => media.notebook`
      width:${({ theme }) => theme.mediaWidth.notebook}
      `}
	${({ theme: { media } }) => media.pad`
      width:${({ theme }) => theme.mediaWidth.pad}
      `}
	${({ theme: { media } }) => media.phone`
      width:${({ theme }) => theme.mediaWidth.phone}
      `} */
	height: 100vh;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	flex: 1;
	flex-wrap: wrap;
	flex-direction: row;
`;

export const UiChildrenContainer = styled.div`
/* width: calc(100% - 200px); */
position: relative;
display: flex;
justify-content: space-between;
overflow-y: auto;
padding: 1rem;
height: calc(100vh - 89.16px);
flex: 1;
flex-direction: column;
`;

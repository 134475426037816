import styled from 'styled-components';

export const UiFileList = styled.div`
	display: block;
	overflow: hidden;
	border: 1px solid ${({ theme }) => theme.palette.line.light};
	border-radius: 5px;
`;

export const UiTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	border-bottom: 1px solid ${({ theme }) => theme.palette.line.light};
	color: ${({ theme }) => theme.palette.btn.normal};
	background-color: ${({ theme }) => theme.palette.background.main};

	svg {
		margin-right: 5px;
		font-size: 1.4rem;
	}
`;

export const UiFileItem = styled.label`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	cursor: ${({ isClick }) => isClick ? 'pointer' : 'default'};

	& > div {
		flex: 1;
		color: ${({ theme }) => theme.palette.btn.highlighthover};
	}

	svg {
		margin-right: 5px;
		font-size: 1.2rem;
	}

	input {
		display: none;
	}
`;

export const UiClose = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
`;

import styled from 'styled-components';
import { Avatar as MatAvatar,GridListTile as MatGridListTile,GridList as MatGridList } from '@material-ui/core';
export const UiPostCardAll = styled.div`
	display: flex;
	width: 100%;
`;

export const UiAvatarBox = styled.div`
	padding: 0.5rem 0.75rem;
`;

export const UiAvatar = styled(MatAvatar)`
	margin: ${({ isSecret }) => isSecret ? '0.5rem 0.5rem 0 0 ' : '0'};
	width: 2.5rem;
	height: 2.5rem;
`;
export const UiPostCardHead = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const UiPostCard = styled.div`
	position: relative;
	width: 100%;
	color: #163e6f;
`;
export const UiIsRead = styled.div`
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;

	& > svg {
		font-size: 1.4rem;
	}
`;

export const UiTeacherInfo = styled.div`

`;

export const UiUserName = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
`;

export const UiUserBox = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 0.75rem;
	min-width: 100px;
	height: min-content;
	margin-bottom: 0.5rem;

	& > .flexBox {
		display: flex;
		align-items: center;
		flex: 1;
	}
`;
export const UiTime = styled.div`
	font-size: 14px;
	color: #8b90a0;
`;

export const UiUser = styled.div`
	padding: 0 1rem;
	font-size: 14px;
	font-weight: 700;
	color: #242c3f;
`;
export const UiContentBox = styled.div`
	padding: 0.5rem 0.75rem;
	color: #163e6f;
	border-bottom: 1px solid #d0e0e2;
`;

export const UiContentText = styled.div`
	overflow: hidden;
	font-size: 1rem;
	letter-spacing: 2px;
	line-height: 1.4;
	color: #3c4858;
	margin-bottom: 2rem;
	word-wrap: break-word;
`;

export const UiMore = styled.div`
	padding: 5px 0 15px;
	width: 100px;
	font-size: 1.2rem;
	font-weight: bold;
	cursor: pointer;
`;

export const UiContent = styled.div`
	font-size: 1rem;
	letter-spacing: 2px;
	line-height: 1.4;
	color: #3c4858;
`;

export const UiChangeButtonBox = styled.div`
	button {
		margin: 8px 8px 8px 0;
	}
`;
export const UiStudentHomeworkForm = styled.div`
	form {
		padding: 0;

		& > div > div > button {
			margin: 8px 8px 8px 0;
		}
	}
`;


export const UiInfo = styled.div`
	display: flex;
	padding: 0.5rem 0.75rem;
	color: #004b62;

	& > div {
		display: flex;
		align-items: center;
		letter-spacing: 1px;
	}
`;

export const UiCircleIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.7rem;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	cursor: pointer;

	& > svg {
		font-size: 1.7rem;

		& > path {
			fill: #f44336;
		}
	}
`;

export const UiComment = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;

	& svg {
		margin-right: 5px;
		font-size: 1.7rem;

		& path {
			fill: #a1a4b1;
		}
	}

	& span {
		padding: 0;
		font-size: 14px;
		color: #3a4052;

		&:hover {
			font-weight: 700;
		}
	}
`;

export const UiLikePeople = styled.div`
	padding: 0 1rem;
	font-size: 14px;
	color: #242c3f;

	& div {
		cursor: pointer;
		width: max-content;
	}

	& span {
		margin-right: 5px;
	}
`;

export const UiMessage = styled.div`
	display: flex;
	overflow: hidden;
	padding: ${({isShow}) => isShow ? '1rem 0.5rem' : '0rem 0.5rem'};
	height: ${({isShow}) => isShow ? 'auto' : 0};
	flex-direction: column-reverse;
	transition: 0.35s;
`;

export const UiMessageItem = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 0.75rem;

	& + div {
		margin-top: 0.8rem;
	}
`;

export const UiAttachmentBox = styled.div`
	a {
		padding: 0.5rem 1rem !important;
		width: 100% !important;
		height: auto !important;
		font-size: 1rem;
		text-decoration: none;
		color: ${({ theme }) => theme.palette.text.normal};
		background-color: ${({ theme }) => theme.palette.background.main};
	}

	video {
		width: 100% !important;
		height: 400px !important;
		border: none;
	}
`;



export const UiReplie = styled.div`
	display: flex;
	background-color: #fafafc;
	border-radius: 16px;

	.inputBox {
		width: calc(100% - 6rem);
	}

	.cancel {
		padding: 2px 0 5px 4rem;

		span {
			color: #e55;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	& + div {
		margin-bottom: 1rem;
	}
`;

export const UiReplieBox = styled.div`
	position: relative;
	width: 100%;
`;

export const UiReplieContent = styled.div`
	padding: 0.5rem 0.75rem;
	width: 100%;
	min-height: 50px;

	span:nth-child(1) {
		display: block;
		margin-right: 5px;
		font-size: 16px;
		color: #3a4052;
		margin-bottom: 5px;
	}

	& > span:first-child {
		font-weight: 700;
	}

	& > div > img {
		margin-top: 10px;
		max-width: 100%;
	}

	& > div > a {
		color: ${({ theme }) => theme.palette.text.sub};
	}
`;

export const UiReplieContentDetail = styled.div`
	margin-top: 10px;
	color: #3a4052;
`;

export const UiAwesomeSpan = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 0.75rem;
	padding-top: 0;
	transition: 0.35s;

	&:hover {
		opacity: 0.8;
	}

	& svg {
		margin-right: 5px;
		font-size: 1.75rem;
		cursor: pointer;
	}
`;
export const UiAwesomeBox = styled.div`
	font-size: 14px;
	color: #3a4052;
	cursor: pointer;

	& > span {
		&:hover {
			font-weight: 700;
		}
	}
`;

export const UiReplieMenu = styled.div`
	padding: 0.5rem 0.75rem;

	& > div {
		&:hover {
			background-color: #f2f4f8;
		}

		& > svg {
			font-size: 2rem;
		}
	}
`;

export const UiPrevMessage = styled.div`
	margin-bottom: 1rem;
	cursor: pointer;
`;


export const UiGridListTile = styled(MatGridListTile)`
	position: relative;

	img {
		cursor: pointer;
	}

	&:hover img {
		opacity: 0.85;
	}
`;

export const UiOnePicture = styled.div`
	width: 100% !important;
	height: 400px !important;
	font-size: 0;

	img {
		cursor: pointer;
		object-fit: scale-down;
		width: 100%;
		height: 100%;
		transition: 0.35s;

		&:hover {
			opacity: 0.85;
		}
	}
`;

export const UiImageBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-size: 2.5rem;
	font-weight: bold;
	color: #ccc;
	background-color: rgba(0, 0, 0, 0.5);
	transition: 0.35s;

	&:hover {
		color: #fff;
	}
`;


export const UiGridList = styled(MatGridList)`
	width: 100%;
`;

import styled from 'styled-components';

export const UiHomeExamTable = styled.div`
	display: block;

	.flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		> h4 {
			margin-right: auto;
			font-size: 20px;
			font-weight: 100;
			color: #242c3f;
			line-height: 30px;
		}

		.end {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			font-size: 12px;
			color: #ccc;
			margin-bottom: 12px;
		}
	}

`;

import styled from 'styled-components';

export const UiCard = styled.div`
	position: relative;
	width: 100%;
	background-color: ${({ backgroundColor }) => backgroundColor};
	border: 1px solid #e4e7ec;
	border-radius: 16px;
	margin-bottom: 1rem;
`;

export const UiCardContent = styled.div`
`;

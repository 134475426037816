import styled from 'styled-components';

export const UiHomeStudentHistoryTable = styled.div`
	display: block;

	> .flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		> h4 {
			font-size: 20px;
			line-height: 30px;
			color: #242c3f;
		}
	}
`;

import styled from 'styled-components';
export const UiCardTitle = styled.div`
	padding-top: ${({ theme }) => `${theme.spacing(1)}px`};
	font-size: 2rem;
	font-weight: bold;
`;

export const UiStats = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

export const UiStatsLabel = styled.div`
	margin-right: 0.5rem;
`;

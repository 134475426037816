import styled from 'styled-components';

export const UiTodoList = styled.div`
	display: block;
	overflow: hidden;
	border: 1px solid ${({ theme }) => theme.palette.line.light};
	border-radius: 5px;

	.MuiFormControl-root {
		display: none;
	}
`;

export const UiTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	color: ${({ theme }) => theme.palette.btn.normal};
	background-color: ${({ theme }) => theme.palette.background.main};
	border-bottom: 1px solid ${({ theme }) => theme.palette.line.light};

	svg {
		margin-right: 5px;
		font-size: 1.4rem;
	}
`;

export const UiTodoItem = styled.label`
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	cursor: ${({ isClick }) => isClick ? 'pointer' : 'default'};

	& + label {
		border-top: 1px solid ${({ theme }) => theme.palette.line.light};
	}

	input {
		margin-right: 5px;
		width: 120px;
		border: none;
		outline: none;
	}

	& > div {
		flex: 1;
		color: ${({ theme }) => theme.palette.btn.highlighthover};
	}

	svg {
		margin-right: 5px;
		font-size: 1.2rem;
	}
`;

export const UiClock = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
`;

import styled from 'styled-components';

export const UiSessionForm = styled.div`
	display: block;
`;

export const UiFilter = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	> * {
		height: max-content;
	}
`;

export const UiSearchInput = styled.div`
	width: 230px;
`;

export const UiPublicFilterBox = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;

	& > * {
		margin-bottom: 10px;
	}
`;

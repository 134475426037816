import styled from 'styled-components';

export const UiUpgradeAccount = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	padding: 5rem 0;
	flex-wrap: wrap;
	width: 100%;
	flex: 1;
	letter-spacing: 2px;

	.MuiAutocomplete-endAdornment {
		top: 0;
	}
`;

export const UiUpgradeAccountImage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15rem;
	color: #ace5ed;
	margin-bottom: 1rem;

	& > svg {
		font-size: 15rem;
		color: #ace5ed;
	}
`;

export const UiUpgradeAccountTitle = styled.div`
	padding: 2rem 0;
	width: 50%;
	font-size: 2rem;
	font-weight: bold;
	text-align: center;
	color: #3c4858;
	border-bottom: 1px solid #d0e0e2;
	margin-bottom: 2rem;
`;

export const UiUpgradeAccountPrompt = styled.div`
	padding: 1rem 0;
	font-size: 1.2rem;
	text-align: center;
	color: #f00;
`;

export const UiUpgradeAccountText = styled.div`
	padding: 1rem 0;
	font-size: 1.2rem;
	text-align: center;

	span {
		color: #f00;
	}
`;

export const UiUpgradeAccountForm = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 100%;
	max-width: 350px;
	flex-direction: column;

	& > div,
	button {
		margin: 1rem 0;
		width: 100%;
	}

`;

import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { TextField as MatTextField } from '@material-ui/core';

import { Checkbox, Button } from 'components';
import { FORGETPASSWORD, REGISTER } from 'constants/index';
import { setOnePaperLoginCookieByServiceRoot } from 'helper/onePaper';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
// import { useDetectionDevice } from 'utils/hooks/useDetectionDevice';
import { getMoeLoginUrl } from 'services/api/login/login';
import cloud from 'assets/images/cloud.png';
import logInImg from 'assets/images/logInImg.png';
import trees from 'assets/images/Trees.png';

import {
  UiLoginForm,
  UiLoginFormFooter,
  UiRow,
  UiFloor1,
  UiFloor,
  UiLoginContent
} from './LoginForm.style';

/**
 * 登入
 */

export const LoginForm = () => {
  const [, { userLogin }] = useUser();

  // const { getDeviceInfo, getBrowserInfo } = useDetectionDevice();
  const [{
    username,
    password,
    rememberMe,
    // isModalOpen,
    // modalText,
    isLoading,
  }, setState] = useSetState({
    username: '',
    password: '',
    rememberMe: false,
    isModalOpen: false,
    modalText: '',
    isLoading: false,
  });

  // const { broswer, version } = getBrowserInfo();
  // const device = getDeviceInfo();
  // const getModalStateHandler = state => {
  //   setState({ isModalOpen: state });
  // };
  // const modalButtons = [
  //   {
  //     text: '確定',
  //     func: () => {
  //       getModalStateHandler(false);
  //     }
  //   }
  // ];
  // const getModalText = () => {
  //   switch (device) {
  //     case 'iphone':
  //       if (broswer === 'Safari') {
  //         if (version < 11) {
  //           setState({
  //             modalText: <p>您目前使用的Safari 瀏覽器版本太舊，為了確保您能獲得最佳的使用體驗，請更新Safari 瀏覽器至11.0之後的版本</p>
  //           });
  //         }
  //       } else {
  //         setState({
  //           modalText: <p>您目前使用的瀏覽器非Safari 瀏覽器，為了確保您能獲得最佳的使用體驗，請改用Safari 瀏覽器。</p>
  //         });
  //       }
  //       break;
  //     default:
  //       if (broswer === 'Chrome') {
  //         if (version < 60) {
  //           setState({
  //             modalText:
  //               <p>
  //                 您目前使用的Chrome瀏覽器版本太舊，為了確保您能獲得最佳的使用體驗，請更新Chrome瀏覽器至
  //                 <a
  //                   href="https://www.google.com/intl/zh-TW/chrome/?brand=CHBD&gclid=Cj0KCQjwsYb0BRCOARI
  //                 sAHbLPhHIx-z8Wp9VLKSs4KoW8e1Uf5sNC25U0PpvRprTDZJ0wqBi0RzXhd0aArrmEALw_wcB&gclsrc=aw.ds"
  //                   target="_blank"
  //                   rel="noopener noreferrer"
  //                 >
  //                   60之後的版本
  //                 </a>
  //               </p>
  //           });
  //         }
  //       } else {
  //         setState({
  //           modalText:
  //             <p>您目前使用的瀏覽器非Chrome瀏覽器，為了確保您能獲得最佳的使用體驗，請改用
  //               <a
  //                 href="https://www.google.com/intl/zh-TW/chrome/?brand=CHBD&gclid=Cj0KCQjwsYb0BRCOARI
  //                   sAHbLPhHIx-z8Wp9VLKSs4KoW8e1Uf5sNC25U0PpvRprTDZJ0wqBi0RzXhd0aArrmEALw_wcB&gclsrc=aw.ds"
  //                 target="_blank"
  //                 rel="noopener noreferrer"
  //               >
  //                 Chrome瀏覽器
  //               </a>
  //               。
  //             </p>
  //         });
  //       }
  //       break;
  //   }
  // };
  // useEffect(() => {
  //   getModalText();
  // }, []);
  // useEffect(() => {
  //   if (modalText !== '') {
  //     getModalStateHandler(true);
  //   }
  // }, [modalText]);

  const onkeyPress = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      submitHandler();
      return false;
    }
  };
  const submitHandler = async () => {
    if (!username || !password) return;
    if (isLoading) return;
    setState({
      isLoading: true
    });
    const formData = {
      username,
      password
    };

    await userLogin(formData);
    rememberMe ? localStorage.setItem('account', username) : localStorage.removeItem('account');
    setState({
      isLoading: false
    });
  };

  const onChangeHandle = (value, key) => {
    setState({
      [key]: value
    });
  };

  const cloudLogin = () => {
    setOnePaperLoginCookieByServiceRoot();
    getMoeLoginUrl();
  };

  useEffect(() => {
    const account = (localStorage.getItem('account')) === null ? '' : localStorage.getItem('account');
    setState({
      username: account,
      rememberMe: account ? true : false
    });
  }, []);

  return (
    <>
      {/* <Modal
        isOpen={isModalOpen}
        content={modalText}
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      >
      </Modal> */}
      <UiLoginContent>
        <div>
          <img src={logInImg} alt='' />
        </div>
        <div>
          <div>
            <UiLoginForm>
              <h1>登入</h1>
              <div className="box">
                <MatTextField
                  style={{ fontSize: '16px' }}
                  label="帳號"
                  aria-label="請輸入帳號"
                  placeholder="請輸入帳號"
                  value={username}
                  variant="outlined"
                  size="small"
                  onChange={e => onChangeHandle(e.target.value, 'username')}
                  onKeyPress={onkeyPress}
                />
              </div>
              <div className="box">
                <MatTextField
                  style={{ fontSize: '16px' }}
                  label="密碼"
                  aria-label="請輸入密碼"
                  placeholder="請輸入密碼"
                  value={password}
                  variant="outlined"
                  size="small"
                  type="password"
                  onChange={e => onChangeHandle(e.target.value, 'password')}
                  onKeyPress={onkeyPress}
                />
              </div>
              <div className="rememberMe">
                <Checkbox
                  label="記住我"
                  checkedValue="rememberMe"
                  onChange={value => {
                    const isRememberMe = value === 'rememberMe' ? true : false;
                    onChangeHandle(isRememberMe, 'rememberMe');
                  }}
                  checked={rememberMe}
                />
              </div>
              <div className="btnBox">
                <Button btnSize="large" loading={isLoading} onClick={submitHandler}>登入</Button>
                <Button
                  btnSize="large"
                  loading={isLoading}
                  buttonColor='ui03'
                  textColor="#242C3F"
                  onClick={cloudLogin}
                >
                  教育雲登入
                </Button>
              </div>
              <UiLoginFormFooter>
                <NavLink to={`/${FORGETPASSWORD}`}>
                  忘記密碼
                </NavLink>
                <span>|</span>
                <NavLink to={`/${REGISTER}`}>
                  註冊帳號
                </NavLink>
              </UiLoginFormFooter>
            </UiLoginForm>

          </div>
          <div>
            <img src={cloud} alt='' />
          </div>
        </div>
      </UiLoginContent>
      <UiFloor1>
        <div>
          <UiRow tabIndex={0}>
            <p>為了讓您獲得最佳瀏覽體驗，建議您使用的瀏覽器軟體為:IE Edge、Google Chrome或Firefox。</p>
            <p>如使用IE瀏覽器瀏覽本網站，可能會發生部分網頁的呈現會有破圖或版面跑版的情況，亦可能導致某些網頁互動功能無法正確顯示與使用。</p>
          </UiRow>
        </div>
        <div>
          <img src={trees} alt='' />
        </div>
      </UiFloor1>

      <UiFloor>
      </UiFloor>

    </>
  );
};


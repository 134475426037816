import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { useUser } from 'store/user';
import { useClass } from 'store/class';
import {
  UiHomeBanner,
  UiHomeContent,
  UiHomeImage,
  UiHomeText,
  UiHomeBackgroundImage,
  UiHomeBox,
  UiSchoolName,
  UiClassName,
  UiOwnerName,
  UiSchoolInfo,
  UiOfficialSiteUrl,
} from './HomeBanner.style';
import defaultBannerPath from 'assets/images/banner.jpg';
import ruleBannerPath from 'assets/images/ruleBanner.jpg';
import organizationDefaultImage from 'assets/images/classBaseImg.png';
import schoolDefaultImage from 'assets/images/school.png';

/**
 * Banner
 */

// eslint-disable-next-line react/display-name
const HomeBannerImage = memo(
  ({ url }) => <UiHomeImage url={url} />,
  (prevProps, nextProps) => prevProps.url === nextProps.url);

export const HomeBanner = ({ showSideBar, isGroupsOrganization = false }) => {
  const [{ myOrganization }, { getMyAllOrganizations }] = useUser();
  const [{ myClasses }, { getMyClasses }] = useClass();
  const { organizationId, classId } = useParams();
  const { dataInfo: myClassInfo } = myClasses;
  const { isHidden, thumbnailUrl: classImg } = myClassInfo;
  const thumbnailUrl = classId ? classImg : myOrganization?.organization?.thumbnailUrl;
  const schoolTitle = info => {
    const { schoolCountyName, schoolName } = info;
    return `${schoolCountyName.split(']')[1] ? schoolCountyName.split(']')[1] : schoolCountyName} - ${schoolName}`;
  };

  useEffect(() => {
    if (showSideBar) {
      getMyClasses();
      if (organizationId) {
        getMyAllOrganizations(true);
      }
    }
  }, []);

  const isShowCityName = myOrganization?.organization?.publicFields?.includes('cityName');

  return (
    <UiHomeBanner>
      <UiHomeContent>
        <UiHomeBox styleType={showSideBar ? 'default' : 'rule'}>

          {
            showSideBar ?
              <>
                <HomeBannerImage
                  url={thumbnailUrl || (organizationId ? organizationDefaultImage : schoolDefaultImage)} />
                <UiHomeText>
                  {
                    isGroupsOrganization ?
                      <>
                        <UiSchoolName>
                          {isShowCityName && `${myOrganization?.organization?.cityName} - `}
                          {myOrganization?.organization?.officialName || ''}
                        </UiSchoolName>
                        <UiSchoolInfo>
                          {
                            myOrganization?.organization?.officialSiteUrl &&
                            <>
                              官方網站：
                              <UiOfficialSiteUrl
                                onClick={() => { window.open(`${myOrganization.organization.officialSiteUrl}`); }}>
                                {myOrganization.organization.officialSiteUrl}
                              </UiOfficialSiteUrl>
                            </>
                          }
                        </UiSchoolInfo>
                        <UiSchoolInfo>
                          {
                            myOrganization?.organization?.address &&
                            `地址：${myOrganization?.organization?.address}, ${myOrganization?.organization?.cityName}`
                          }
                        </UiSchoolInfo>
                      </> :
                      <>
                        <UiSchoolName>
                          {isShowCityName && `${myOrganization?.organization?.cityName} - `}
                          {myClassInfo.organizationName || ''}
                          {
                            myClassInfo.schoolId &&
                            schoolTitle(myClassInfo)
                          }
                          {
                            myClassInfo.year && ` - ${myClassInfo.year}學年度`
                          }
                        </UiSchoolName>
                        <UiClassName>
                          {myClassInfo.grades && (myClassInfo.grades.length > 0 ? `${myClassInfo.grades[0]}年級 - ` : '')}
                          {myClassInfo.name && `${myClassInfo.name}${(isHidden ? '(隱藏)' : '')}`}
                        </UiClassName>
                        {
                          myClassInfo.ownerName &&
                          <UiOwnerName>導師：{myClassInfo.ownerName}</UiOwnerName>
                        }
                      </>
                  }
                </UiHomeText>
              </> :
              <>
                <UiHomeText >
                  <h1>政策暨服務條款</h1>
                </UiHomeText>
              </>
          }

        </UiHomeBox>
      </UiHomeContent>
      <UiHomeBackgroundImage>
        <img src={showSideBar ? defaultBannerPath : ruleBannerPath} alt="banner" />
      </UiHomeBackgroundImage>
    </UiHomeBanner>
  );
};

HomeBanner.propTypes = {
  organizationName: PropTypes.string,
  groupName: PropTypes.string,
  imagePath: PropTypes.string,
  showSideBar: PropTypes.bool,
  GroupsOrganization: PropTypes.bool
};



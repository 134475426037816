import { Api } from 'services/api';


/**
 * 更改使用者權限
 */
export const changeUserRole = (organizationId, userId) => async payload => {
  const response = await Api.put(`/organizations/${organizationId}/users/${userId}`, payload);
  return response;
};

/**
 * 更改組織使用者權限
 */
export const editOrganizationGroupControls = (organizationId) => async payload => {
  const response = await Api.put(`/organizations/${organizationId}/group-access-controls`, payload);
  return response;
};

import styled from 'styled-components';

export const UiCustomerForm = styled.div`
	display: flex;
	justify-content: center;
	padding: 20px;
	flex-wrap: wrap;
`;

export const UiCoustomerButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

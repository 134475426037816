import styled from 'styled-components';

export const UiHomeRuleInfo = styled.div`
	display: block;
`;

export const UiRuleTitle = styled.div``;

export const UiRuleContent = styled.div`
& li {
	margin: 1rem 0;
}

& > ol {
	& > li > ul {
		list-style-type: none;
	}

	& > li > ol {
		list-style-type: upper-alpha;

		& > li > ol {
			list-style-type: lower-alpha;
		}
	}
}
`;

import styled from 'styled-components';

export const UiHomeInfo = styled.div`
	display: block;
`;

export const UiHomeInfoTitle = styled.div`
	font-size: 20px;
	color: ${({ theme }) => theme.palette.text.normal};
	margin-bottom: 1rem;
`;

export const UiInfoBox = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
	font-size: 1rem;
	color: ${({ theme }) => theme.palette.text.normal};
`;

export const UiDescription = styled.div`
	margin-right: 1rem;
	width: 70%;
	max-width: 60%;
	font-style: normal;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #000;
`;

export const UiDetail = styled.div`
	padding: 10px 27px;
	background-color: #fff;
	border: 1px solid #f2f4f8;
	border-radius: 5px;

	& > div {
		display: flex;
		align-items: center;
		padding: 3px 0;
		width: 100%;
		letter-spacing: 0.05em;
		line-height: 140.62%;
		color: #8b90a0;

		& > svg {
			margin-right: 10px;
			font-size: 1.7rem;
			color: ${({ theme }) => theme.palette.text.normal};
			color: #3a4052;
		}
	}
`;

import styled from 'styled-components';

export const UiNameInput = styled.div`
	display: flex;
	justify-content: space-between;

	& > div {
		width: 48%;
	}
`;
export const UiInput = styled.div`
	display: inline-flex;
	flex-direction: column;
`;
export const UiHelperText = styled.div`
	& p {
		color: #f44336;
	}
`;

import styled from 'styled-components';

export const AvatarUploadBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px;
	height: 20vh;
	border: 1px dashed #000;
	border-radius: 20px;
	flex-direction: column;

	& > div {
		padding-top: 0.5rem;
	}
`;

export const UiAvatarFrame = styled.div`
	overflow: hidden;
	width: 250px;
	height: 250px;
	border: 1px solid #ddd;
	border-radius: 50%;
`;

export const UiAvatarImg = styled.div`
	width: 100%;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: ${(props) => `url(${props.url}) `};
`;

import styled from 'styled-components';

export const UiInviteOrganizationGroupHostForm = styled.div`
	display: block;
	margin: auto;
	margin-top: 1rem;
	width: 60%;
`;



export const UiCheckboxWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

export const UiBatchList = styled.div`
	margin: 1.5rem 0;
`;

export const UiButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 1.5rem 0;

	& > button {
		margin: 0 0.5rem;
	}
`;

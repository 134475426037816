import styled from 'styled-components';

export const UiClassProfileImageCard = styled.div`
	position: relative;
	display: block;
	max-width: 300px;
	border: 1px solid;
	overflow-wrap: break-word;
	border-color: #d8edf0;

	& > svg {
		position: absolute;
		top: 10px;
		right: 10px;
	}
`;

export const UiPictureContent = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 5px;
	width: 100%;
	min-height: 43.75px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 5;
	transition: 0.5s;
`;

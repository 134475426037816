import styled from 'styled-components';

export const UiSectionCardItem = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 0;
`;

export const UiLabel = styled.span`
	display: inline-block;
	padding-right: 0.5rem;
	color: ${({ theme }) => theme.palette.primary.main};
`;

export const UiContent = styled.span`
	display: inline-block;
	color: ${({ theme }) => theme.palette.primary.main};
`;

import { getUnixTime } from 'date-fns';

export const useDateInterval = () => {
  //獲取今日 00:00:00
  const today = new Date().toLocaleDateString('zh-TW');
  const nowTimeStamp = getUnixTime(new Date(new Date().toGMTString())) * 1000;
  //獲取今日 23:59:59
  const todayLastTime = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
  const startAtTimeStamp = getUnixTime(new Date(today)) * 1000;
  const endAtTimeStamp = getUnixTime(new Date(todayLastTime)) * 1000;
  const todayInterval = () => {
    return {
      startAt: startAtTimeStamp,
      endAt: endAtTimeStamp
    };
  };
  const processingInterval = () => {
    return {
      startAt: getUnixTime(new Date()) * 1000,
      endAt: getUnixTime(new Date()) * 1000
    };
  };
  const pastInterval = () => {
    return {
      startAt: 0,
      endAt: (getUnixTime(new Date(today)) - 1) * 1000
    };
  };
  const futureInterval = () => {
    return {
      startAt: endAtTimeStamp + 2000,
      endAt: 0
    };
  };
  const allInterval = () => {
    return {
      startAt: 0,
      endAt: 0
    };
  };

  return [
    { nowTimeStamp, startAtTimeStamp, endAtTimeStamp },
    { todayInterval, processingInterval, pastInterval, futureInterval, allInterval }
  ];
};



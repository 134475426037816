import styled from 'styled-components';
import {
  Tabs as MatTabs,
} from '@material-ui/core';

export const UiHomeStudentVideoTable = styled.div`
	display: block;

	> h4 {
		font-size: 20px;
		line-height: 30px;
		color: #242c3f;
	}
`;




export const UiMatTabs = styled(MatTabs)`
	display: block;
	margin: 1rem 0;

	.MuiTab-textColorInherit.Mui-selected {
		color: #ec7963;
	}
	
	.MuiTab-wrapper {
		font-size: 1rem;
	}

	> div {
		border-bottom: 1px solid #e8e8e8;
	}

	> div > span {
		background-color: #ec7963;
	}
`;

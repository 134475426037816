import styled from 'styled-components';

export const UiMemberSeatsSelectTable = styled.div`
	display: block;
`;

export const UiMemberSeatsWrapper = styled.div`
	display: block;
	overflow-y: auto;
	max-height: 600px;
`;

export const UiMemberSeatsContent = styled.div`
	border-top: 1px solid rgba(224, 224, 224, 1);
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	display: flex;

	justify-content: space-between;
	flex-wrap: wrap;

	& > div:nth-child(3n+2) {
		border-right: none;
		border-left: none;
	}

`;
export const UiMemberSeatsItem = styled.div`
	display: flex;
	padding: 1rem 2rem;
	width: 33.3333333%;
	border-right: 1px solid rgba(224, 224, 224, 1);
	border-left: 1px solid rgba(224, 224, 224, 1);
	color: ${({ theme }) => theme.oneClass.text.palette.default};

	& > * {
		display: flex;
		align-items: center;
		width: 100%;
	}

	& > div > button {
		margin: auto 0 auto auto;
	}
`;

export const UiMemberInfo = styled.div``;
export const UiMemberNumber = styled.div`
	width: 2rem;

`;

export const UiMemberSeatsButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;

	& > button {
		margin: 1rem;
	}
`;

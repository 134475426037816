import { Api } from 'services/api';

/**
 * 檢查帳號是否存在
 */
export const accountIsExsit = async params => {
  const body = JSON.stringify(params);
  const response = await fetch(`${process.env.REACT_APP_ONECLUB_USERVALID_DOMAIN}/userExist`, {
    method: 'POST',
    body
  });
  if (response.ok) {
    return await response.text();
  }
  return response;
};

/**
 * 忘記密碼傳送簡訊
 */
export const forgetPassword = async params => {
  const response = await Api.post(`${process.env.REACT_APP_ONECLUB_DOMAIN}/forgetMobilePassword`, params);
  return response;
};

/**
 * 忘記密碼傳送簡訊
 */
export const forgetPasswordCheckMobileValid = async params => {
  const response = await Api.post(`${process.env.REACT_APP_ONECLUB_DOMAIN}/checkForgetPasswordMobileValid`, params);
  return response;
};

/**
 * 忘記密碼傳送簡訊
 */
export const revisePassword = async params => {
  const response = await Api.post(`${process.env.REACT_APP_ONECLUB_DOMAIN}/resetMobilePassword`, params);
  return response;
};

/**
 * 註冊傳送簡訊
 */
export const registerSendValodCode = async params => {
  const response = await Api.post(`${process.env.REACT_APP_ONECLUB_DOMAIN}/signupMobile`, params);
  return response;
};

/**
 * 註冊檢查驗證碼
 */
export const registerCheckMobileValid = async params => {
  const response = await Api.post(`${process.env.REACT_APP_ONECLUB_DOMAIN}/checkMobileValid`, params);
  return response;
};

/**
 * 註冊
 */
export const register = async params => {
  const response = await Api.post(`${process.env.REACT_APP_ONECLUB_DOMAIN}/createMobileAccount`, params);
  return response;
};



/**
 * 修改密碼
 */
export const changePassword = async params => {
  params.jwt = getLocalCookies();
  const response = await Api.post(`${process.env.REACT_APP_MEMBERS_API_DOMAIN}/changePassword`, params);
  return response;
};


/**
 * 檢查密碼(導師商談)
 */
export const checkTokenAndUserPassword = async params => {
  const response =
    await Api.post(`${process.env.REACT_APP_ONECLUB_USERCHECK_DOMAIN}/checkTokenAndUserPassword`, params);

  return response;
};



/**
 * 取得登入的token
 */
const getLocalCookies = () => {
  const naniToken = document.cookie.replace(/(?:(?:^|.*;\s*)nani_oneclass_login_token\s*=\s*([^;]*).*$)|^.*$/, '$1');
  const jwt = JSON.parse(naniToken).jwt;
  return jwt;
};

import styled from 'styled-components';
export const UiSingleLayout = styled.div`
	display: flex;
	height: 100%;
	min-height: 100vh;
`;

export const UiSingleContent = styled.div`
	display: grid;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	grid-template-areas: 'header header' 'leftSide main';
	grid-template-rows: auto 1fr;
	grid-template-columns: ${({ showLeftSidebar }) => showLeftSidebar ? '0.35fr 0.65fr' : '0fr'};

	.header {
		grid-area: header;
	}

	> .leftSidebar {
		display: flex;
		overflow: hidden;
		padding: 30px 48px;
		background: #fafafc;
		flex-direction: column;
		grid-area: leftSide;
		background-image: ${({ cloud2 }) => ` url(${cloud2}) `};
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: contain;

		> .logo {
			font-size: 0;
			margin-bottom: 30px;

			> a {
				object-fit: contain;
				cursor: pointer;

				> img {
					height: 40px;
				}
			}
		}

		> .greetings {
			padding: 14px 0;
			font-size: 32px;
			font-weight: 700;
			color: #242c3f;
			line-height: 36px;
		}

		> .dashSkeleton {
			margin: 16px 0;
		}

		> .main {
			display: flex;
			padding: 20px 0;
			flex-direction: column;
			flex: 1;

			> .subTitle {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 2rem;

				> h3 {
					font-size: 20px;
					font-weight: 700;
					color: #454b5c;
					line-height: 32px;
				}

				> a {
					font-size: 16px;
					text-decoration: none;
					color: #8b90a0;
					line-height: 24px;
				}
			}

			> .wtfIssue {
				overflow: hidden;
				margin: auto;
				width: 90%;
				font-size: 0;
				border-radius: 12px;
				cursor: pointer;

				> img {
					width: 100%;
					border-radius: 12px;
					object-fit: contain;
					object-position: center;
				}
			}
		}
	}

	> .main {
		overflow-x: hidden;
		padding: 14px 18px 30px;
		height: 100%;
		grid-area: main;
		background-color: #fff;
	}
`;
export const UiEmptyContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100%;
	text-align: center;
`;

export const UiHelloTitle = styled.div`
	display: flex;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 9px;
	}

	h2 {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
// export const UiSingleContent = styled.div`
// 	flex: 1;
// 	margin: auto;
// 	width: 95%;
// 	font-family: ${({theme})=>theme.typography.fontFamily};
// 	${({ theme: { media } }) => media.desktop`
// 		width:${({ theme }) => theme.mediaWidth.desktop}
// 		`}
// 	${({ theme: { media } }) => media.notebook`
// 		width:${({ theme }) => theme.mediaWidth.notebook}
// 		`}
// 	${({ theme: { media } }) => media.pad`
// 		width:${({ theme }) => theme.mediaWidth.pad}
// 		`}
// 	${({ theme: { media } }) => media.phone`
// 		width:${({ theme }) => theme.mediaWidth.phone}
// 		`}
// `;

import styled from 'styled-components';

export const UiAside = styled.div`
	${({ theme }) => `
    display: block;
    background-color: ${theme.palette.background.paper};
    width:170px;
    margin: 0.8rem;
    border: 1px solid ${theme.palette.primary.main};
  `}
`;

export const UiAsideTitle = styled.div`
	font-size: 1.1rem;
	text-align: center;
	color: #fff;
	background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const UiAsideText = styled.div`
	${({ theme }) => `
    padding:1rem;
    font-family: ${theme.text.fontFamily};
  `}
`;

export const UiAsideItem = styled.div`
	font-size: 1rem;
	text-align: center;
	color: ${({ theme }) => theme.palette.primary.main};
	background-color: ${({ theme, active }) => active ? '#fcffea' : '#fff'};
	cursor: pointer;
	transition: 0.5s;

	&:hover {
		background-color: #fcffea;
	}

	& + div {
		border-top: 1px solid ${({ theme }) => theme.palette.primary.main};
	}


`;

import styled from 'styled-components';


export const UiOrganizationForm = styled.div`
	display: flex;
	padding: 20px;

	.right {
		margin-left: 40px;
		flex: 1;
	}
`;

export const UiHomeSettingButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 14px;
	width: 100%;
	color: ${({ theme }) => theme.palette.text.normal};
	background-color: ${({ theme }) => theme.palette.btn.menu};
	border-radius: 15px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;

	& > svg {
		margin-right: 0.5rem;
		font-size: 1.5rem;
		color: ${({ theme }) => theme.palette.text.show};
	}

	& > input {
		display: none;
	}
`;

export const UiButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	& > button + button {
		margin-left: 0.5rem;
	}
`;
export const UiFilterBox = styled.div`
	display: flex;
	margin-bottom: 20px;
`;

export const UiButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	& > button {
		margin: 0.5rem 0;
	}

	& > button + button {
		margin: 0.5rem 0.5rem 0.5rem;
	}
`;

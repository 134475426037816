import styled from 'styled-components';

export const UiAvatarMenu = styled.div`
	display: block;
`;

export const UiButtonWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const UiNickname = styled.div`
	font-size: 1rem;
	color: #555;
	cursor: pointer;
`;

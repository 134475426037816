import styled from 'styled-components';

export const UiNewBatchList = styled.div`
	display: block;
	overflow: hidden;
	border: 1px solid ${({ theme }) => theme.palette.block.light};
	border-radius: 5px;
`;


export const UiTitle = styled.div`
	padding: 1rem;
	background-color: ${({ theme }) => theme.palette.line.light};
`;

export const UiBatchItem = styled.label`
	display: flex;
	align-items: center;
	padding: 1rem;
	cursor: pointer;

	& + label {
		border-top: 1px solid ${({ theme }) => theme.palette.block.light};
	}
`;

export const UiInput = styled.input`
	padding: 0.5rem;
	font-size: 1.1rem;
	color: ${({ error }) => error ? '#f44336' : '#000'};
	border: 0;
	outline: none;
`;

export const UiIconBox = styled.div`
	display: flex;
	align-items: center;


	& > svg {
		font-size: 1.4rem;
	}
`;

export const UiCloseBox = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;

	& > svg {
		font-size: 1.4rem;
	}
`;

export const UiHelperText = styled.div`
	& p {
		color: #f44336;
	}
`;

import styled from 'styled-components';

export const UiOrganizationResourceTable = styled.div`
  padding: 20px;
`;

export const UiActionComponentWraper = styled.div`
  display: flex;
  justify-content: center;
  margin: -16px;
  label {
    margin: 0;
  }
`;

export const UiAddResourseInputGroup = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
  button {
    margin-left: 30px;
  }
  span {
    font-size: 14px;
    padding: 0 10px;
  }
  h6{
    width: 300px;
  }
  svg {
    cursor: pointer;
  }
`;


export const UiDeleteModalText = styled.div`
  font-size: 30px;
`;

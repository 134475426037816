import styled from 'styled-components';

export const UiHomeAnnouncementForm = styled.div`
	display: block;

	& > form {
		padding: 0;
	}
`;

export const UiDateTitle = styled.div`
	padding: 0 1.5rem;
	font-size: 1.25rem;
	font-weight: bolder;
	color: #163e6f;
`;

export const UiFlexBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 1rem;

	& > button + button {
		margin-left: 1rem;
	}
`;

export const UiDateTimePicker = styled.div`
	display: flex;
`;
export const UiCheckboxGroup = styled.div`
		display: flex;
		align-items: center;

		& * {
			color: #000;
		}
`;

export const UiCheckboxGroupText = styled.div`
	font-size: 1rem;
`;

export const UiActionBox = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	& > div:nth-child(1) {
		width: 55%;
	}

	& > div:nth-child(2) {
		width: 43%;
	}
`;

export const UiForm = styled.div`
	display: block;
`;

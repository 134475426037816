import styled from 'styled-components';

export const UiSessionSummary = styled.div`
	display: block;
`;

export const UiSummaryTitle = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 48px;
	padding-bottom: 1rem;
	border-bottom: 1px solid #d5d7de;
	margin-bottom: 1rem;
`;

export const UiSummaryClass = styled.div`
	font-size: 25px;
	font-weight: 700;
`;

export const UiSummaryHostBox = styled.div`
	font-size: 1.25rem;
	display: flex;
	align-items: center;
`;
export const UiSummaryIconBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 10px;
	padding-right: 10px;

	span {
		color: #a1a4b1;
	}

	&::after {
		position: absolute;
		right: 0;
		width: 1px;
		height: 70%;
		background-color: #c4c4c4;
		content: '';
	}
`;
export const UiSummaryHostName = styled.div`
`;


export const UiSummaryContent = styled.div`
	display: flex;
`;

export const UiSummaryInfo = styled.div`
	padding: 3rem 0;
	width: 40%;
	text-align: center;
	background-color: #fef8f7;
	border-radius: 8px;

	> div {
		margin: 10px auto;
	}

	> button {
		margin: 20px auto;
		border-radius: 50px;
	}
`;
export const UiInfoClassName = styled.div`
	font-size: 24px;
`;
export const UiInfoClassTeacher = styled.div`
	font-size: 16px;
	color: #8b90a0;
	line-height: 24px;
`;
export const UiInfoClassDataTime = styled.div`
	font-size: 1rem;

	> span:first-child {
		position: relative;
		margin-right: 10px;
		padding-right: 10px;

		&::after {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			width: 1px;
			height: 70%;
			background-color: #3a4052;
			content: '';
		}
	}
`;

export const UiSummaryTime = styled.div`
	display: flex;
	justify-content: space-between;
	width: 60%;
	flex-direction: column;
`;

export const UiTimeCardBox = styled.div`
	display: flex;
`;
export const UiTimeCard = styled.div`
	display: flex;
	align-items: center;
	margin: 0 8px;
	padding: 1.3rem 0;
	padding-left: 1rem;
	width: 50%;
	background-color: #fafafc;
	border-radius: 8px;
`;
export const UiTimeBox = styled.div`
	padding: 1rem 1.75rem;
	border-radius: 8px;
`;
export const UiTimeIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 64px;
	height: 64px;
	background-color: #f9c74f;
	border-radius: 20px;

	> svg {
		font-size: 2.5rem;
	}
`;
export const UiTimeText = styled.div`
	font-size: 20px;
	color: #4e5d78;
`;
export const UiTimeNumber = styled.div`
	font-size: 40px;
	font-weight: 700;
	color: ${({ haveNumber }) => haveNumber ? '' : '#F6564B'};

	> span {
		font-size: 25px;
	}
`;
export const UiTimeSession = styled.div`
	font-size: 1rem;
	color: #8b90a0;
`;
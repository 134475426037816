import styled from 'styled-components';

export const UiUploadImageBox = styled.div`
	display: block;

	.img {
		position: relative;
		overflow: hidden;
		width: 200px;
		height: 200px;
		border-radius: 5px;
		margin-bottom: 20px;

		svg {
			position: absolute;
			top: 5px;
			right: 5px;
			opacity: 0;
			transition: 0.35s;
			cursor: pointer;
		}

		&:hover svg {
			opacity: 1;
		}
	}

	.button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 12px;
		width: 100%;
		color: #fff;
		background-color: #009be5;
		border-radius: 5px;
		cursor: pointer;
		opacity: 1;
		transition: 0.35s;

		&:hover {
			opacity: 0.8;
		}

		svg {
			margin-right: 5px;
		}

		input {
			display: none;
		}
	}
`;

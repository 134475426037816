import styled from 'styled-components';

export const UiSeatBox = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	margin: 15px 0;
`;

export const UiSeatItem = styled.div`
	position: relative;
	margin: 0.25rem;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	font-weight: bold;
	text-align: center;
	color: #fff;
	background-color: ${({ theme, active }) => active ? '#f38a04' : theme.palette.primary.main};
	opacity: ${({ isChoosed, active }) => isChoosed ? active ? 1 : 0.5 : 1};
	transition: 0.35s;
	flex: 0 1 calc(10% - 0.5rem);
	cursor: ${({ isChoosed }) => isChoosed ? 'default' : 'pointer'};

	&:hover {
		box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
	}
`;

export const UiRemoveItem = styled.div`
	position: absolute;
	top: -5px;
	right: -5px;

	& > svg {
		font-size: 1.2rem;
	}
`;

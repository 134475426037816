import styled from 'styled-components';
import { Typography as MatTypography, Divider as MatDivider } from '@material-ui/core';

export const UiLoginContent = styled.div`
	display: flex;
	width: 100%;
	max-width: 1280px;
	height: 100%;

	& > div:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 65%;
		text-align: center;

		& > img {
			width: 100%;
		}
	}

	& > div:last-child {
		padding: 7%;
		height: 100%;
		text-align: center;

		& > div:first-child {
			display: flex;
			justify-content: center;
			text-align: inherit;
		}

		& > div:last-child {
			text-align: end;
		}
	}
`;

export const UiLoginForm = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40px 0 10px 0;
	padding: 0 1.2rem 1.2rem 1.2rem;
	width: 38%;
	min-width: 300px;
	max-width: 480px;
	background-color: #fff;
	border: 1px solid #f2f4f8;
	flex-direction: column;
	border-radius: 10px;

	& > h1 {
		font-style: normal;
		font-size: 32px;
		font-weight: 400;
		line-height: 36px;
	}

	> .box {
		width: 100%;
		max-width: 320px;
		margin-bottom: 17px;

		> div {
			width: 100%;
		}

		label {
			font-size: 16px;
		}

		input {
			padding: 1rem 1.75rem;
		}
	}

	> .rememberMe {
		display: flex;
		padding-left: 5px;
		width: 100%;
		max-width: 320px;
		margin-bottom: 17px;

		span {
			font-size: 16px;
		}

		> div {
			width: 100%;
		}
	}

	> .btnBox {
		margin-bottom: 24px;
		width: 100%;
		max-width: 320px;

		span {
			font-size: 16px;
		}

		> button {
			width: 100%;

			& + button {
				margin-top: 12px;
			}
		}
	}
`;

export const UiLoginFormFooter = styled.div`
	font-size: 14px;
	text-align: center;
	color: #a1a4b1;

	a {
		padding: 0 1rem;
		text-decoration: none;
		color: #a1a4b1;
	}
`;

export const UiRow = styled.div`
	margin: 33px auto 0;
	width: 100%;
	font-size: 12px;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	text-align: center;
	color: #8b90a0;
	line-height: 16px;

	& p {
		font-size: 12px;
		line-height: 140.62%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #a1a4b1;
	}
`;

export const UiContactUs = styled.div`
	margin: auto;
	width: 38%;
	min-width: 300px;
	max-width: 700px;
`;

export const UiIconList = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1rem auto 0;
	width: 100%;
`;

export const UiIconRow = styled.a`
	display: block;
	width: 33%;
	text-align: center;
	cursor: pointer;
	text-decoration: none;

	& > svg {
		font-size: 24px;
		color: #ec7963;
	}

	& > div {
		margin-top: 20px;
		color: #3c4858;
	}
`;


export const UiTypography = styled(MatTypography)`
	margin-bottom: 2rem;
	width: 100%;
	text-align: center;
`;

export const UiDivider = styled(MatDivider)`
`;

export const UiFloor1 = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	max-width: 1280px;
	height: 100%;

	& > div:first-child {
		padding: 12px;
		width: 80%;
	}

	& > div:last-child {
		position: absolute;
		bottom: 0;
		right: 3%;
		display: flex;
		margin: 0 auto;
		width: 14%;
	}
`;
export const UiFloor = styled.div`
	width: 100%;
	height: 25px;
	background-color: #e5e5e5;
`;



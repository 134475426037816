import styled from 'styled-components';

export const UiContentSearch = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const UiSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const UiInputContainer = styled.div`
`;
import styled from 'styled-components';
import MatButton from '@material-ui/core/Button';

export const UiOutlineButton = styled(MatButton)`
	position: relative;
	display: flex;
	padding: 0.75rem 1rem;
	min-height: 40px;
	font-size: ${({ size })=> size ? '14px' : '1rem'};

	color:
		${(
			{ theme,
			textColor,
			buttoncolor}
		) => textColor ? textColor : (buttoncolor === 'disable'? '#D5D7DE' : theme.palette.btn[buttoncolor])};

	background-color:
		${(
			{
				buttoncolor,
				loading }
			) => (buttoncolor === 'disable' || loading === true) ?'#F2F4F8' : '#fcfcfa'};

	border: ${({buttoncolor}) => buttoncolor === 'disable' ? '0px': '1px solid'};

	border-color:
		${(
			{ theme,
			textColor,
			buttoncolor}
		) => textColor ? textColor : (buttoncolor === 'disable'? '' : theme.palette.btn[buttoncolor])};

	cursor:
		${(
			{
				buttoncolor,
				loading
			}
		) => (buttoncolor === 'disable' || loading === true) ? 'not-allowed' : 'pointer'};

	&:hover {
		color: ${({theme, buttoncolor}) => buttoncolor === 'disable' ? '' : theme.palette.btn[buttoncolor + 'hover']};

		background-color:
			${(
				{
					theme,
					buttoncolor
				}
			) => buttoncolor === 'disable' ? theme.palette.btn[buttoncolor] : '#fcfcfa'};
	}
`;

export const UiInlineFlex = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;

	& > svg {
		margin-left: ${({ endIcon }) => endIcon && '5px'};
		font-size: 1.7rem;

		path {
			fill: ${({theme, buttoncolor}) => buttoncolor ? theme.palette.btn[buttoncolor] : ''};
		}
	}
`;
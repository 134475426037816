import styled from 'styled-components';

export const UiPdfUploader = styled.div`
	display: block;
	padding: 0;

	& > form {
		padding: 0;
	}
`;

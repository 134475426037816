import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import waittingReviewImage from 'assets/images/waittingReview.png';
import {
  getOrganizationsByScroll as getOrganizationsByScrollApi
} from 'services/api/organization/organizations';
import phone from 'assets/images/phone.png';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { getTeacherVerification } from 'services/api/home/users';
import { useIntersectionObserver } from 'utils/hooks/useIntersectionObserver';
import { TextField, NativeSelect, FormControl, InputBase, Tooltip as MatTooltip } from '@material-ui/core';
import {
  Button,
  Modal,
  Icon,
  BsModal,
  Checkbox,
  SchoolCitySelector,
  OrganizationContainer,
  GroupCard,
  Loading,
  BlindBrick,
  EmptyPrompt
} from 'components';
import { useSchoolYear } from 'utils/hooks/useSchoolYear';
import { UiflexBox } from 'styles';
import QRCode from 'qrcode.react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  UiMyOrganizations,
  UiModal,
  UiModalInput,
  UiUnitButton,
  UiStep1,
  UiStep2,
  UiStep2Input,
  UiStep2Checkbox,
  UiStep2CheckboxText,
  UiStep3,
  UiStep3Input,
  UiStep4,
  UiStep4Text,
  UiPictureBox,
  UiQrModal,
  UiQrBox,
  UiQrText,
  UiQrPicture,
  UiEmptyContent
} from './MyOrganizations.style';
import forest from 'assets/images/forest.svg';

const ALL_SCHOOL_YEAR = 'all';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    zIndex: 2,
  },

  margin: {
    margin: theme.spacing(1),
    width: '120px',
  },
}));

/**
 * 我的組織列表
 */


export const MyOrganizations = () => {
  const history = useHistory();
  const classes = useStyles();
  const ref = useRef(null);
  const { setAlert } = useAlert();
  const [
    { profile, isNeedReload }, { isNeedReloadDashBoard }
  ] = useUser();
  const [, { joinClassByInvitationCode }] = useClass();
  const { schoolYearOptionsWithAll: schoolYearOptions } = useSchoolYear();
  const [isFetching, setIsFetching] = useState(false);
  const [{
    isLoading,
    isOpenModal,
    isModalBtnDisabled,
    InviteCode,
    year,
    isOpenBsModal,
    unitState,
    validationTeacherForm,
    isRead,
    contactMobileNumberError,
    countDownEnd,
    schoolCityData,
    organizationsData,
    organizationsTotal,
    organizationsNowPage,
    organizationsRowsPage,
    isQrModal,
  }, setState] = useSetState({
    isLoading: false,
    isOpenModal: false,
    isModalBtnDisabled: true,
    InviteCode: null,
    year: ALL_SCHOOL_YEAR,
    isOpenBsModal: false,
    unitState: 'chooseUnit',
    validationTeacherForm: {
      contactMobileNumber: null,
      userName: null,
      userMobileNumber: null,
      educationName: null,
      schoolCityName: null,
      schoolDistrictName: null,
      schoolId: null,
    },
    isRead: false,
    contactMobileNumberError: false,
    countDownEnd: false,
    schoolCityData: null,
    organizationsData: [],
    organizationsTotal: 0,
    organizationsNowPage: 0,
    organizationsRowsPage: 10,
    isQrModal: false,
  });

  const onFilterChange = key => event => setState({ [key]: event.target.value });

  const isBottomVisible = useIntersectionObserver(ref, {
    threshold: 0
  }, false);

  const isEmptyUser = useMemo(() => {
    return profile.organizations.length === 0;
  }, [profile]);

  const openModalHandler = () => {
    setState({ isOpenModal: true });
  };

  const getModalStateHandler = state => {
    setState({ isOpenModal: state });
  };

  // 輸入代碼改變事件
  const inputChangeHandler = value => {
    setState({
      InviteCode: value.replace(/[^a-zA-Z]/gi, '').toUpperCase(),
    });
    if (value.length >= 6) {
      setState({
        isModalBtnDisabled: false,
      });
    } else {
      setState({ isModalBtnDisabled: true });
    }
  };

  // 教師驗證 ＡＰＩ
  const fetchTeacherVerification = async params => {
    const { isSuccess, error } = await getTeacherVerification(params);
    if (isSuccess) {
      return isSuccess;
    } else {
      const { errorCode } = error;
      if (errorCode === 18010) {
        setState({ contactMobileNumberError: true });
      } else {
        setAlert('驗證失敗!', 'error');
      }
    }
  };

  // 教師驗證 轉886格式
  const formatPhone = phone => {
    const startPhone = phone?.slice(0, 1);
    const lastPhone = phone?.slice(1, 10);
    if (startPhone === '0') {
      return phone.slice(0, 1).replace(0, '+886') + lastPhone;
    }
  };

  const submitHandle = async () => {
    let isSuccess = false;
    switch (unitState) {
      case 'validationTeacher':
        setState({ unitState: 'validationPhone' });
        break;
      case 'validationPhone':
        validationTeacherForm['contactMobileNumber'] = formatPhone(validationTeacherForm['contactMobileNumber']);
        validationTeacherForm['userMobileNumber'] = formatPhone(validationTeacherForm['userMobileNumber']);
        isSuccess = await fetchTeacherVerification(validationTeacherForm);
        if (isSuccess) {
          setState({ unitState: 'waittingReview' });
          countDown();
        }
        break;
      case 'waittingReview':
        setState({ unitState: 'validationTeacher' });
        break;
      default:
        break;
    }
  };

  const modalToggle = () => {
    switch (unitState) {
      case 'validationTeacher':
        setState({ unitState: 'chooseUnit' });
        break;
      case 'validationPhone':
        setState({ unitState: 'validationTeacher' });
        break;
      default:
        break;
    }
  };

  const okDisplayState = () => {
    let isDisplay = true;

    switch (unitState) {
      case 'chooseUnit':
        isDisplay = false;
        break;
      case 'waittingReview':
        isDisplay = countDownEnd;
        break;
      default:
        break;
    }

    return isDisplay;
  };

  const cancelDisplayState = () => {
    let isDisplay = true;

    switch (unitState) {
      case 'chooseUnit':
        isDisplay = false;
        break;
      case 'waittingReview':
        isDisplay = !countDownEnd;
        break;
      default:
        break;
    }

    return isDisplay;
  };

  const titleState = () => {
    let title = '';
    switch (unitState) {
      case 'chooseUnit':
        title = '選擇您的所屬單位';
        break;
      case 'validationTeacher':
        title = '驗證教師身份';
        break;
      case 'waittingReview':
        title = '等待審核';
        break;
      default:
        break;
    }

    return title;
  };

  const okTextState = () => {
    let text = '';
    switch (unitState) {
      case 'chooseUnit':
        text = '';
        break;
      case 'validationTeacher':
        text = '下一步';
        break;
      case 'validationPhone':
        text = '送出審核';
        break;
      case 'waittingReview':
        text = countDownEnd ? '重新填寫' : '';
        break;
      default:
        break;
    }

    return text;
  };

  const cancelTextState = () => {
    let text = '';
    switch (unitState) {
      case 'chooseUnit':
        text = '';
        break;
      case 'validationTeacher':
      case 'validationPhone':
        text = '上一步';
        break;
      default:
        break;
    }

    return text;
  };

  const okDisabledState = () => {
    let filterSalsePhoneForm = {};
    let newValidationTeacherForm = {};
    let isDisabled = false;
    switch (unitState) {
      case 'validationTeacher':
        filterSalsePhoneForm = Object.assign({}, validationTeacherForm);
        delete filterSalsePhoneForm['contactMobileNumber'];
        newValidationTeacherForm = Object.values(filterSalsePhoneForm).every(value => value);
        if (isRead && newValidationTeacherForm) {
          isDisabled = false;
        } else {
          isDisabled = true;
        }
        break;
      case 'validationPhone':
        isDisabled = validationTeacherForm.contactMobileNumber?.length === 10 ? false : true;
        break;
      default:
        break;
    }

    return isDisabled;
  };

  const teacherNameHandle = value => {
    const name = {
      userName: value,
    };
    setState({ validationTeacherForm: { ...validationTeacherForm, ...name, } });
  };

  const teacherPhoneHandle = value => {
    const nameNumber = {
      userMobileNumber: value,
    };
    setState({ validationTeacherForm: { ...validationTeacherForm, ...nameNumber, } });
  };

  const isReadHandler = value => {
    setState({ isRead: Boolean(value) });
  };

  const validationPhoneHandle = value => {
    const contactMobileNumber = {
      contactMobileNumber: value,
    };
    setState({ validationTeacherForm: { ...validationTeacherForm, ...contactMobileNumber, } });
  };

  const schoolCityHandle = val => {
    const educationName = { educationName: val.educationName };
    const schoolId = { schoolId: val.organizationId };
    const schoolCityName = { schoolCityName: val.cityName };
    const schoolDistrictName = { schoolDistrictName: val.districtName };
    setState({
      validationTeacherForm: {
        ...validationTeacherForm,
        ...educationName,
        ...schoolId,
        ...schoolCityName,
        ...schoolDistrictName,
      }
    });
  };

  const countDown = time => {
    const timeout = time ? time : 60000;
    setTimeout(() => {
      setState({
        isLoading: false,
        countDownEnd: true,
      });
      return true;
    }, timeout);
  };

  // 加入班級事件
  const joinClassHandler = async () => {
    setState({ isloading: true });
    const isSuccess = await joinClassByInvitationCode(InviteCode);
    setState({ isloading: false });
    if (isSuccess) {
      setState({ isOpenModal: false });
      await fetchOrganizations();
    }
  };

  const fetchOrganizations = async () => {
    setIsFetching(true);
    const params = {
      nowPage: isNeedReload ? 0 : organizationsNowPage,
      rowsPage: organizationsRowsPage
    };
    const {
      data: { organizations, total },
      isSuccess
    } = await getOrganizationsByScrollApi(params);
    setIsFetching(false);
    if (isSuccess) {
      const nextOrganizationsData = organizations.map(item => {
        const isOwner = (item.organization?.permissionGroups && item.organization?.permissionGroups.length > 0);
        return {
          ...item.organization,
          isOwner,
          role: item.userProfile.role,
          userId: item.userProfile.id
        };
      });

      const newOrganizationsData = isNeedReload ? nextOrganizationsData : organizationsData.concat(nextOrganizationsData);
      setState({
        organizationsData: newOrganizationsData,
        organizationsTotal: total,
        organizationsNowPage: isNeedReload ? 0 : organizationsNowPage + 1
      });
      isNeedReloadDashBoard(false);
    }
  };

  useEffect(() => {
    if (profile.teacherVerificationApplication) {
      setState({ validationTeacherForm: profile.teacherVerificationApplication });
    }
  }, [profile]);

  useEffect(() => {
    if (unitState === 'waittingReview') {
      setState({ isLoading: true });
      if (profile.teacherVerificationApplication) {
        setState({
          schoolCityData: {
            educationName: validationTeacherForm.educationName,
            cityName: validationTeacherForm.schoolCityName,
            districtName: validationTeacherForm.schoolDistrictName,
            organizationId: validationTeacherForm.schoolId,
          }
        });

        // 計時器
        const nowTime = new Date().getTime();
        const appliedAt = profile.teacherVerificationApplication.appliedAt;
        const timer = nowTime - appliedAt;
        if (timer > 60000) {
          setState({
            isLoading: false,
            countDownEnd: true,
          });
          okDisplayState();
          okTextState();
        } else {
          const time = 60000 - timer;
          countDown(time);
        }
      }
    }
  }, [unitState]);

  useEffect(() => {
    (isBottomVisible && organizationsData.length < organizationsTotal) && fetchOrganizations();
  }, [isBottomVisible]);

  useEffect(() => {
    //第一次
    fetchOrganizations();
  }, [isNeedReload]);


  return (
    <UiMyOrganizations>
      <BlindBrick className='accesskey' id='accessibility_center_container' accessKey='c' href='#accessibility_center_container' title='中央內容區塊，為本頁主要內容區。' />
      <div className="actionBox">
        <h1 tabIndex={0}>我的班級</h1>
        <UiflexBox>
          <FormControl className={classes.margin}>
            <NativeSelect
              id="demo-customized-select-native"
              value={year}
              label="學年度"
              placeholder="篩選學年度"
              onChange={onFilterChange('year')}
              input={<BootstrapInput />}
            >
              {schoolYearOptions.map((item, index) => {
                return (
                  <option key={index} value={item.value}>{item.name}</option>
                );
              })}
            </NativeSelect>
          </FormControl>
          {
            !isEmptyUser && 
            <MatTooltip arrow title="輸入班級代碼，加入您的班級" open={false}>
              <div style={{ marginRight: '10px' }}>
                <Button
                  buttonColor='new'
                  icon='add'
                  onClick={openModalHandler}
                >加入班級</Button>
              </div>
            </MatTooltip>
          }
        </UiflexBox>
      </div>
      {
        isFetching ? <Loading /> :
          isEmptyUser ? 
          <UiEmptyContent>
            <EmptyPrompt img={forest} title='尚未加入任何班級' message={<p>點擊加入班級按鈕，輸入班級代碼<br />或查看右上角通知，接受老師邀請並加入班級</p>} messageColor={'#8B90A0'}></EmptyPrompt>
            <MatTooltip arrow title="輸入班級代碼，加入您的班級" open={false}>
              <div style={{ marginTop: '16px' }}>
                <Button
                  buttonColor='new'
                  icon='add'
                  onClick={openModalHandler}
                >加入班級</Button>
              </div>
            </MatTooltip>
          </UiEmptyContent> :
          organizationsData.length > 0 &&
          organizationsData.map(organization => {
            return (
              <OrganizationContainer
                key={organization.id}
                name={organization.officialName}
                organizationId={organization.id}
                isOwner={organization.isOwner}
              >
                <GroupCard
                  organizationId={organization.id}
                  year={year}
                  isOwner={organization.isOwner}
                  role={organization.role}
                  userId={organization.userId}
                />
              </OrganizationContainer>
            );
          })
      }
      <div ref={ref} style={{ width: '100%', height: '20px' }}></div>
      <Modal
        isOpen={isOpenModal}
        size='small'
        title='加入班級'
        isIconShow={false}
        content={
          <UiModal>
            <UiModalInput>
              <TextField
                placeholder="請輸入班級代碼(共六碼大寫英文字母)"
                variant="outlined"
                inputProps={{ maxLength: 6 }}
                value={InviteCode}
                onChange={e => inputChangeHandler(e.target.value)}
              />
            </UiModalInput>
            <div style={{ margin: '8px 0', display: 'flex', justifyContent: 'space-between' }}>
              <Button buttonColor="cancel" onClick={() => setState({ isOpenModal: false })}>取消</Button>
              <Button
                buttonColor="new"
                loading={isLoading}
                disabled={isModalBtnDisabled}
                onClick={joinClassHandler}
              >加入班級</Button>
            </div>
          </UiModal>
        }
        getModalState={getModalStateHandler}
      />
      <BsModal
        open={isOpenBsModal}
        title={titleState()}
        okText={okTextState()}
        cancelText={cancelTextState()}
        onOk={submitHandle}
        onCancel={modalToggle}
        oKDisplay={okDisplayState()}
        cancelDisplay={cancelDisplayState()}
        okDisabled={okDisabledState()}
        isLoading={isLoading}
        btnPositionStyle='center'
        onClose={() => setState({ isOpenBsModal: false })}
      >
        {/* 選擇您的所屬單位 */}
        {
          unitState === 'chooseUnit' && <UiStep1>
            <UiUnitButton onClick={() => { setState({ unitState: 'validationTeacher' }); }}>
              <Icon.Svg name="School" />
              <div>國中小及高中</div>
            </UiUnitButton>
            <UiUnitButton onClick={() => { window.open('https://tutoring.bitrix24.site/'); }}>
              <Icon.Svg name="Vector" />
              <div>補習班</div>
            </UiUnitButton>
            <UiUnitButton onClick={() => { setState({ isQrModal: true }); }}>
              <Icon.Svg name="AirPlane" />
              <div>海外僑校</div>
            </UiUnitButton>
          </UiStep1>
        }
        {/* 驗證教師身份 */}
        {
          unitState === 'validationTeacher' && <UiStep2>
            <div>請填寫您的基本資料與服務學校</div>
            <UiStep2Input>
              <TextField
                label="姓名"
                variant="outlined"
                inputProps={{ maxLength: 6 }}
                style={{ width: '100%' }}
                value={validationTeacherForm.userName}
                onChange={e => teacherNameHandle(e.target.value)}
              />
            </UiStep2Input>
            <UiStep2Input>
              <TextField
                label="手機號碼"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
                style={{ width: '100%' }}
                value={validationTeacherForm.userMobileNumber}
                onChange={e => teacherPhoneHandle(e.target.value)}
              />
            </UiStep2Input>
            <SchoolCitySelector
              data={schoolCityData}
              variant='outlined'
              isGrades={false}
              isSchoolCityName={true}
              onChange={schoolCityHandle}
            />
            <UiStep2Checkbox>
              <Checkbox
                checkedValue={true}
                checked={isRead}
                onChange={isReadHandler}
              />
              <UiStep2CheckboxText>
                我已閱讀並且同意
                <span onClick={() => history.push('/rule')}>OneLink 服務條款</span>
              </UiStep2CheckboxText>
            </UiStep2Checkbox>
          </UiStep2>
        }
        {/* 驗證教師身份 - 手機 */}
        {
          unitState === 'validationPhone' && <UiStep3>
            <div>為了確認您的教師身份，請輸入任一南一業務手機號碼，以利後續驗證</div>
            <UiStep3Input>
              <TextField
                error={contactMobileNumberError}
                label="南一業務手機號碼"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
                style={{ width: '100%' }}
                onChange={e => validationPhoneHandle(e.target.value)}
                helperText={contactMobileNumberError ? '此號碼非南一業務手機，請重新輸入' : ''}
              />
            </UiStep3Input>
          </UiStep3>
        }
        {/* 等待審核 */}
        {
          unitState === 'waittingReview' && <UiStep4>
            <UiPictureBox path={waittingReviewImage} />
            <UiStep4Text>
              <div>我們已收到您填寫的資料，將會盡快為您處理</div>
              <div>如有急件請聯繫南一業務夥伴，感謝您的耐心等候！</div>
            </UiStep4Text>
          </UiStep4>
        }
      </BsModal>
      <BsModal
        open={isQrModal}
        onCancel={() => setState({ isQrModal: !isQrModal })}
        oKDisplay={false}
        cancelDisplay={false}
        isLoading={isLoading}
        backgroundColor="#FEF2F0"
      >
        <UiQrModal>
          <UiQrBox>
            <QRCode size={180} value="https://line.me/R/ti/p/%40412ljgqi" />
          </UiQrBox>
          <UiQrText>
            <UiQrPicture path={phone} />
            <div>
              <div>請掃描上方QR code</div>
              <div>加入LINE客服</div>
              <div>我們將有專人為您服務</div>
            </div>
          </UiQrText>
        </UiQrModal>
      </BsModal>
    </UiMyOrganizations>
  );
};



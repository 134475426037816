import React from 'react';
import { UiFooter, UiRow, UiLink, UiContent } from './Footer.style';
import { useHistory } from 'react-router-dom';

/**
 * 在這邊描述這個組件
 */

export const Footer = () => {
  const history = useHistory();
  const goToRule = () => {
    history.push('/rule');
  };
  return (
    <UiFooter>
      <UiRow>
        <UiContent>v{process.env.REACT_APP_VERSION}</UiContent>
      </UiRow>

    </UiFooter>
  );
};

Footer.propTypes = {

};



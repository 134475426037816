import styled from 'styled-components';

export const UiPermissionSetting = styled.div`
	display: block;

	.buttonBox {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 45px auto 0;

		& > * {
			margin-right: 10px;
		}
	}
`;

export const UiTable = styled.div`
	display: flex;
	width: 100%;
	border: 1px solid #e5e5e5;
	flex-wrap: wrap;

	.title {
		display: flex;
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		background-color: #0787cf;

		& > div {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px 5px;
			width: 116px;
			font-weight: bold;
			color: #fff;

			&:nth-child(2) {
				text-align: center;
				flex: 1;
			}
		}
	}

	.head {
		.row {
			display: flex;
			width: 116px;

			&:nth-child(odd) {
				background-color: #fff;
			}

			&:nth-child(even) {
				background-color: #f4f3f0;
			}

			.item {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 64px;
				font-size: 1.2rem;
				color: #505565;
			}
		}
	}

	.body {
		position: relative;

		background-color: #aaa;
		flex: 1;

		.main {
			overflow-y: hidden;
			background-color: #fff;
		}
	}

	.tr {
		display: flex;
		align-items: center;
		width: 100%;

		& > .td:nth-child(1),
		.th:nth-child(1) {
			flex: 0 0 116px;
			position: sticky;
			left: 0;
			z-index: 1;
		}

		&:nth-child(odd) {
			background-color: #fff;

			& > .td,
			.th {
				background-color: #fff;
			}
		}

		&:nth-child(even) {
			background-color: #f4f3f0;

			& > .td,
			.th {
				background-color: #f4f3f0;
			}
		}

		.th {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 64px;
			font-size: 14px;
			font-weight: 400;
			color: #505565;
			flex: 1 0 115px;
		}

		.td {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 64px;
			font-size: 14px;
			font-weight: 400;
			color: #505565;
			flex: 1 0 115px;
		}
	}
`;

import styled from 'styled-components';

export const UiHomePostForm = styled.div`
	display: block;
	margin-top: 2rem;
`;

export const UiDateTitle = styled.div`
	padding: 0 1.5rem;
	font-size: 1.25rem;
	font-weight: bolder;
	color: #163e6f;
`;
import styled from 'styled-components';
export const UiBackgroundImg = styled.div`
	&.image {
		margin-right: 12px;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-color: #eee;
		background-image: ${({ img }) => img && `url(${img}) `};
	}
`;

export const UiDashBoardCard = styled.a`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	padding: 20px 24px;
	width: 90%;
	height: 120px;
	background: #fff;
	border-radius: 12px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
	transition: 0.35s;
	cursor: pointer;

	&:hover {
		box-shadow: 0 6px 7px rgba(0, 0, 0, 0.2), 0 5px 6px rgba(0, 0, 0, 0.17);
	}

	> .image {
		margin-right: 12px;
		width: 80px;
		font-size: 0;
		text-align: center;

		> img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	> .info {
		flex: 1;

		> .info_item {
			align-items: center;
			justify-content: space-between;
			padding-bottom: 12px;

			> .title {
				overflow: hidden;
				max-width: 160px;
				font-size: 20px;
				text-overflow: ellipsis;
				white-space: nowrap;
				color: #2f3649;
			}

			> .badge {
				font-style: normal;
				font-size: 14px;
				font-weight: 400;
				line-height: 24px;
				color: #242c3f;
			}

			> .time {
				display: flex;
				align-items: center;
				font-size: 18px;
				font-weight: 600;
				color: #8b90a0;
				line-height: 28px;
				letter-spacing: 0.75px;

				> span {
					display: inline-block;
					margin-left: 4px;
					padding: 2px 8px;
					font-size: 14px;
					color: #242c3f;
					background-color: #f2f4f8;
					border-radius: 4px;
					line-height: 16px;
				}
			}

			> .name {
				font-size: 14px;
				color: #a1a4b1;
				line-height: 24px;
			}

			& + .info_item {
				margin-top: 5px;
			}
		}

		> .bottom_info {
			display: flex;
			align-items: center;
			justify-content: space-between;

			> .title {
				overflow: hidden;
				max-width: 160px;
				font-size: 16px;
				text-overflow: ellipsis;
				white-space: nowrap;
				color: #2f3649;
			}

			> .badge {
				font-style: normal;
				font-size: 14px;
				font-weight: 400;
				line-height: 24px;
				color: #242c3f;
			}

			> .time {
				display: flex;
				align-items: center;
				font-size: 18px;
				font-weight: 600;
				color: #8b90a0;
				line-height: 28px;
				letter-spacing: 0.75px;

				> span {
					display: inline-block;
					margin-left: 4px;
					padding: 2px 8px;
					font-size: 14px;
					color: #242c3f;
					background-color: #f2f4f8;
					border-radius: 4px;
					line-height: 16px;
				}
			}

			> .name {
				font-size: 14px;
				color: #242c3f;
				line-height: 24px;
			}

			& + .info_item {
				margin-top: 5px;
			}
		}
	}
`;

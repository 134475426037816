import React from 'react';
import { UiLoading, UiLine } from './Loading.style';


/**
 * Loading 組件
 */

export const Loading = () => {
  return (
    <UiLoading>
      <UiLine />
      <UiLine />
      <UiLine />
    </UiLoading>
  );
};



import React from 'react';
import { Button } from 'components';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UiOrganizationContainer, UiHead, UiGroupsBox } from './OrganizationContainer.style';
import { Icon } from 'components/molecules/Icon/Icon';


/**
 * 組織集合
 */

export const OrganizationContainer = ({ organizationId, name, isOwner, children }) => {
  const history = useHistory();

  const goMyOrganization = () => {
    history.push(`/home/${organizationId}/announcement`);
  };

  const goOrg = () => {
    // eslint-disable-next-line max-len
    window.location.href = `https://onelink-chiayi-oms${process.env.REACT_APP_ENV === 'release' ? '' : '-' + process.env.REACT_APP_ENV}.oneclass.com.tw/home?organizationId=${organizationId}`;
  };

  return (
    <UiOrganizationContainer>
      <UiHead>
        <span tabIndex={0}><Icon.Svg name="School" />{name}</span>
        {isOwner && <Button buttonColor='ui03' textColor="#242C3F" onClick={goOrg}>後台管理</Button>}
        <Button buttonColor='new' onClick={goMyOrganization}>機構公告</Button>
      </UiHead>
      <UiGroupsBox>
        {children}
      </UiGroupsBox>
    </UiOrganizationContainer>
  );
};

OrganizationContainer.propTypes = {
  organizationId: PropTypes.string,
  name: PropTypes.string,
  isOwner: PropTypes.bool,
  children: PropTypes.node
};



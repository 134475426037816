import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import defaultImage from 'assets/images/classBaseImg.png';
import schoolDefaultImage from 'assets/images/school.png';
import { availableEntryNavigation } from 'constants/index';
import {
  UiGroupsCard,
  UiGroupsHead,
  UiGroupsBox,
  UiGroupItem,
  UiGroupsPrompt,
  UiRoleIcon,
  UiGroupsImage,
} from './GroupsCard.style';



/**
 * 我的班級卡牌
 */

export const GroupsCard = ({
  data,
  type,
  schema,
  imagePath,
  groupActionButton,
  actionButton,
  oranganizationRole
}) => {
  const history = useHistory();

  const [{
    organizationId,
    organizationName,
    list
  }, setState] = useSetState({
    organizationId: '',
    organizationName: '',
    list: []
  });

  const goMyGroup = (organizationId, classId, entryNavigationFunction) => {
    history.push(organizationId ?
      `/home/${organizationId}/class/${classId}/${availableEntryNavigation[entryNavigationFunction]}` :
      `/home/class/${classId}/sessions`
    );
  };

  const goMyOrganization = (organizationId, classId) => {
    history.push(`/home/${organizationId}/announcement`);
  };

  const educationNameFormat = educationName => {
    const educationNameText = {
      E: '國小',
      J: '國中',
      H: '高中'
    };
    return educationNameText[educationName];
  };

  const dataFormat = data => {
    setState({
      organizationId: data.organization ? data.organization.id : '',
      organizationName: data.organization ? data.organization.officialName : data.school.officialName,
      list: data.myClassList
    });
    return;
  };

  const countyFormat = county => {
    return county.split(']')[1] ? county.split(']')[1] : county;
  };

  const gradesFormat = arr => {
    if (arr.length === 0) return '';
    let str = '';
    arr.forEach(item => {
      str += `${item},`;
    });
    return `${str.substring(0, str.length - 1)} 年級  - `;
  };

  const RoleIcon = ({ role }) => {
    switch (role) {
      case 'organizationOwner':
        return (
          <div className="tag">機構管理者</div>
        );
      case 'groupOwner':
        return (
          <div className="tag">導師</div>
        );
      case 'staff':
        return (
          <div className="tag">教師</div>
        );
      case 'customer':
        return (
          <div className="tag">學生</div>
        );
      default:
        return '';
    }
  };

  useEffect(() => {
    if (data.length === 0) return;
    dataFormat(data);
  }, [data]);

  return (
    <UiGroupsCard>
      <UiGroupsHead type={type}>
        {
          data.organization?.publicFields?.includes('cityName') && <span>{data.organization.cityName}-</span>
        }
        {
          list.length > 0 && list[0].schoolCountyName && <span>
            {list[0].schoolCountyName && `${countyFormat(list[0].schoolCountyName)}-`}
          </span>
        }
        <span>{organizationName || ''}</span>
        {groupActionButton && groupActionButton}
        {
          type !== 'school' &&
          <Button buttonColor='new' onClick={() => { goMyOrganization(organizationId); }}>進入學校</Button>
        }
      </UiGroupsHead>
      {
        list.length > 0 ?
          <UiGroupsBox>
            {
              list.map(li => {
                return (
                  <UiGroupItem
                    key={li.id}
                    onClick={() => goMyGroup(li.organizationId, li.id, li.entryNavigationFunction)}
                  >
                    <UiGroupsImage
                      url={li.thumbnailUrl || (type === 'organization' ? defaultImage : schoolDefaultImage)}
                    />
                    <UiRoleIcon>
                      <RoleIcon role={li.groupRole} />
                    </UiRoleIcon>
                    <div>
                      <div>
                        {educationNameFormat(li.educationName)}
                        {li.year && `${li.year}學年度`}
                      </div>
                      <div>{li.grades && gradesFormat(li.grades)}{li.name}</div>
                    </div>
                  </UiGroupItem>
                );
              })
            }
          </UiGroupsBox>
          :
          <UiGroupsPrompt>
            {
              groupActionButton ?
                '您目前尚未建立任何班級，請點選後台管理、進入管理後台建立班級' :
                (oranganizationRole === 'staff' ? '您目前尚未被安排為授課教師或導師，請聯絡該機構相關負責人員' : '尚未加入班級')
            }
          </UiGroupsPrompt>
      }
    </UiGroupsCard>
  );

};


GroupsCard.defaultProps = {
  actionButton: {
    name: '進入班級',
    func: () => { }
  },
  isAdmin: false
};


GroupsCard.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
  schema: PropTypes.object,
  imagePath: PropTypes.string,
  actionButton: PropTypes.shape({
    name: PropTypes.string,
    func: PropTypes.func
  }),
  groupActionButton: PropTypes.node,
  oranganizationRole: PropTypes.string
};



import React from 'react';
import PropTypes from 'prop-types';
import lrz from 'lrz';
import MatImage from 'material-ui-image';
import { Icon } from 'components';
import organizationDefaultImage from 'assets/images/classBaseImg.png';
import { UiUploadImageBox } from './UploadImageBox.style';


/**
 * 上傳前預覽組件
 */
const noop = () => { };
export const UploadImageBox = ({ src, upload = noop, label = '機構圖片變更', remove = noop }) => {

  const changeThumbnailUrl = async file => {
    await lrz(file).then(async res => {

      for (let value of res.formData.values()) {
        upload(src = res.base64, value);
      }
    });
  };

  return (
    <UiUploadImageBox>
      <div className="img">
        <MatImage src={src || organizationDefaultImage} alt="org" />
        {src && <Icon name="delete" onClick={remove} />}
      </div>
      <label className='button'>
        <Icon name="image" haveBg={false} />
        {label}
        <input type="file" onChange={e => changeThumbnailUrl(e.target.files[0])} />
      </label>
    </UiUploadImageBox>
  );
};

UploadImageBox.propTypes = {
  src: PropTypes.string,
  upload: PropTypes.func,
  remove: PropTypes.func,
  label: PropTypes.string,
};



import styled from 'styled-components';
export const UiPictureCard = styled.div`
	position: relative;
	display: block;
	max-width: 300px;
	cursor: pointer;
`;

export const UiPictureContent = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px;
	width: 100%;
	min-height: 43.75px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 5;
	transition: 0.5s;
`;

export const UiPictureLabel = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	font-size: 1rem;

	& > svg {
		font-size: 1.4rem;
		color: ${({ isLiked, theme }) => isLiked ? theme.palette.user.block.bar : '#bbb'};
	}
`;

export const UiPictureFooterContent = styled.div`
	display: flex;
	align-self: center;
`;

import styled from 'styled-components';

export const UiHomeBanner = styled.div`
position: relative;
display: block;
padding-top: 40px;
width: 100%;
min-height: 280px;
`;

export const UiHomeImage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin-right: 15px;
	width: 200px;
	height: 200px;
	font-size: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #eee;
	border-radius: 5px;
	background-image: ${(props) => `url(${props.url}) `};

	/* & > div {
		width: 100%;
	} */
`;

export const UiHomeText = styled.div`
position: relative;
color: #fff;
z-index: 2;

& > h1 {
	font-size: 2.5rem;
}

& > h2 {
	font-size: 2rem;
}

& > h3 {
	margin-top: 0;
	font-size: 1.5rem;
}
`;

export const UiHomeBackgroundImage = styled.div`
position: absolute;
top: 0;
right: 0;
left: 0;
overflow: hidden;
width: 100%;
height: 100%;
font-size: 0;

&::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 99;
}

& > img {
	position: absolute;
	top: 0;
	width: 100%;

	filter: blur(2px);
}
`;

export const UiHomeBox = styled.div`
	position: absolute;
	top: ${({ styleType }) => styleType === 'rule' ? '60px' : '85px'};
	display: flex;
`;

export const UiSchoolName = styled.div`
	margin: 0 0 0.5rem;
	font-size: 1.75rem;
	font-weight: 700;
`;

export const UiClassName = styled.div`
	margin: 0;
	font-size: 1.75rem;
	font-weight: 700;
`;

export const UiOwnerName = styled.div`
	font-size: 1.25rem;
`;

export const UiSchoolInfo = styled.div`
font-size: 1rem;
`;


export const UiOfficialSiteUrl = styled.span`
cursor: pointer;
`;

export const UiHomeContent = styled.div`
position: relative;
display: flex;
margin: auto;
width: 95%;
font-family: ${({ theme }) => theme.typography.fontFamily};
z-index: 999;
flex: 1;
${({ theme: { media } }) => media.desktop`
      width:${({ theme }) => theme.mediaWidth.desktop}
      `}
${({ theme: { media } }) => media.notebook`
      width:${({ theme }) => theme.mediaWidth.notebook}
      `}
${({ theme: { media } }) => media.pad`
      width:${({ theme }) => theme.mediaWidth.pad}
      `}
${({ theme: { media } }) => media.phone`
      width:${({ theme }) => theme.mediaWidth.phone}
      `}
`;

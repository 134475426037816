import styled from 'styled-components';

export const UiHomeMemberProfileCardEdit = styled.div`
	display: block;
	margin: auto;
	width: 75%;
	text-align: center;

	& > form > div {
		text-align: left;
	}

	& .MuiOutlinedInput-root {
		background-color: #fff;
	}
`;

export const UiHomeMemberProfileLabelGroup = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 auto;
	padding-bottom: 1rem;
	width: 50%;

	& > button {
		margin: 0 0.5rem;
	}
`;

import styled from 'styled-components';

export const UiBlindBrick = styled.a`
	position: absolute;
	left: 0;
	display: block;

	text-decoration: none;
	color: #fff;

	:focus {
		color: #ec7963;
		background-color: rgba(236, 121, 99, 0.1);
	}

`;

import styled from 'styled-components';

export const UiMemberCenterCard = styled.div`
	position: relative;
	margin: 0 auto 1rem;
	width: 75%;
	border-radius: 5px;
	box-shadow: 3px 3px 7px #aaa;
`;
export const UiCardTitle = styled.div`
	padding: 1rem;
	font-size: 1.4rem;
	text-align: center;
`;
export const UiCardContent = styled.div``;

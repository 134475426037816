import styled from 'styled-components';

export const UiHomePicture = styled.div`
	display: block;
`;

export const UiPictureHeader = styled.div`
margin-bottom: 1.5rem;
`;

export const UiPictureTableHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	& > nav {
		padding-bottom: 0;
	}
`;

export const UiPictureTableTitle = styled.div`
	font-size: 1.25rem;
	font-weight: bolder;
	color: #163e6f;

`;

export const UiModal = styled.div`
	& > div {
		margin: 1rem 0;
	}
`;

export const UiModalInput = styled.div`
	margin: 1rem 0;
`;

export const UiPictureList = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;

	& > div {
		width: 16%;

		&:not(:nth-child(6n + 1)) {
			margin-left: 0.8%;
		}
	}
`;

export const UiPictureProfileList = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;

	& > div {
		width: 24%;
		margin-bottom: 0.8%;

		&:not(:nth-child(4n + 1)) {
			margin-left: 0.8%;
		}
	}
`;

export const UiButtonWrapper = styled.div`
	display: flex;

	& > button,
	& > div {
		margin-left: 1rem;
	}
`;

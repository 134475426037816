/* eslint-disable max-len */
import styled from 'styled-components';

export const UiSideBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 288px;
	height: calc(100vh - 89.16px);
	flex-direction: column;

	& > div + div {
		margin-top: 8px;
	}
`;

export const UiSideBarItemsContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-top: 0.5rem;
`;

export const UiSideBarItem = styled.div`
	width: 200px;
	height: 56px;
	border-radius: 12px;

	a {
		display: flex;
		align-items: center;
		padding: 12px 24px;
		max-height: 52.5px;
		font-size: 1rem;
		text-decoration: none;
		color: ${({ active, disabled }) => disabled ? '#d5d7de' : (active ? '#ec7963' : '#91949E')};
		background-color: ${({ active }) => active ? 'rgba(236,121,99,0.1)' : '#fff'};
		border-radius: 12px;
		transition: 0.5s;
		line-height: 32px;
		cursor: pointer;

		&:hover {
			background-color: #fef2f0;
		}

		& > svg {
			margin-right: 1rem;
			font-size: 1.8rem;

			& > path {
				fill: ${({ active, disabled }) => disabled ? '#d5d7de' : (active ? '#ec7963' : '#8B90A0')};
			}
		}
	}
`;

export const UiSideBarSecond = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 12px 24px;
	max-height: 52.5px;
	font-size: 1rem;
	text-decoration: none;
	color: ${({ active }) => active ? '#ec7963' : '#8B90A0'};
	background-color: ${({ active }) => active ? 'rgba(236,121,99,0.1)' : '#fff'};
	border-radius: 12px;
	transition: 0.5s;
	line-height: 32px;

	& > button {
		padding: 0;
		font-size: 1rem;
		color: ${({ active, disabled }) => disabled ? '#d5d7de' : (active ? '#ec7963' : '#91949E')};
		background-color: transparent;
		border: 0;
	}

	li {
		list-style-type: none;
	}

	ul,
	span {
		opacity: ${({ showMenu }) => showMenu ? 1 : 0};
		visibility: ${({ showMenu }) => showMenu ? 'visible' : 'hidden'};
		transition: visibility 0s, opacity 0.3s linear;
	}
	cursor: pointer;

	& > svg {
		margin-right: 1rem;
		font-size: 1.8rem;

		& > path {
			fill: ${({ active }) => active ? '#ec7963' : '#8B90A0'};
		}
	}

	&:hover {
		background-color: rgba(238, 135, 115, 0.1);

		ul,
		span {
			opacity: 1;
			visibility: visible;
			transition: visibility 0s, opacity 0.3s linear;
		}
	}
`;

export const UiSideBarSecondBox = styled.ul`
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	width: 210px;
	background-color: #fff;
	border-radius: 12px;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
	box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
	transform: translateX(100%);
`;

export const UiSideBarSecondItem = styled.li`
	margin-top: 8px;

	&:first-child {
		margin-top: 0;
	}

	& > a {
		display: flex;
		align-items: center;
		padding: 12px 24px;
		max-height: 52.5px;
		font-size: 1rem;
		text-decoration: none;

		/* color: ${({ active }) => active ? '#ec7963' : '#8B90A0'}; */
		color: ${({ active, disabled }) => disabled ? '#d5d7de' : (active ? '#ec7963' : '#8B90A0')};
		background-color: ${({ active }) => active ? 'rgba(236,121,99,0.1)' : '#fff'};
		border-radius: 12px;
		line-height: 32px;
		cursor: pointer;

		&:hover {
			background-color: ${({ active, disabled }) => disabled ? '#fff' : (active ? 'rgba(238,135,115,0.1)' : '#f5f5f5')};
		}

		& > svg {
			margin-right: 1rem;
			font-size: 1.8rem;

			& > path {
				/* fill: ${({ active }) => active ? '#ec7963' : '#8B90A0'}; */
				fill: ${({ active, disabled }) => disabled ? '#d5d7de' : (active ? '#ec7963' : '#8B90A0')};
			}
		}
	}
`;

export const UiSideBarSecondItemIcon = styled.span`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	height: 21px;
	visibility: hidden;

	& > svg {
		font-size: 1.8rem;

		& > path {
			fill: #8b90a0;
		}
	}
`;

export const UiNew = styled.div`
	position: absolute;
	right: 2rem;
	padding: 0 4px;
	font-size: 8px;
	color: #fff;
	background-color: #ec7963;
	border-radius: 2px;
	line-height: 1.7;
`;

export const UiLogo = styled.div`
	padding: 1.0667rem 1.6rem;
	width: 100%;
	height: 80px;
	text-align: center;
`;

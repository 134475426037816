import styled from 'styled-components';

export const UiSideBarCard = styled.div`
	display: block;
	border: 1px solid #ccc;
`;

export const UiTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem;
	width: 100%;
	font-size: 1rem;
	color: #0393a4;
	background-color: #eee;
	border-bottom: 1px solid #ccc;
`;

export const UiTitleMain = styled.div`
	flex: 1;
	display: flex;
	align-items: center;

	& > svg {
		margin-right: 0.5rem;
		font-size: 1.5rem;
	}
`;

export const UiContent = styled.div`
	margin: ${({ edit }) => edit ? '3rem' : ''};
	padding: 0.5rem;
	width: 100%;
	height: 10rem;
`;

export const UiContentMore = styled.div`
	position: absolute;
	bottom: 5px;
	right: 5px;
	cursor: pointer;
`;

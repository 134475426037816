import styled from 'styled-components';

export const UiHomeworkSubmittedTable = styled.div`
	display: block;
`;

export const UiAttachmentLink = styled.a`
	font-weight: bold;
`;

export const UiStudentLink = styled.div`
	font-weight: bold;
	text-decoration: underline;
	color: #00e;
	cursor: pointer;
`;

export const UiFileActionBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	& > div {
		margin: 0 4px;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
`;

import React, { useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import logo from 'assets/images/chiayi_logo.svg';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import { AvatarMenu, Icon, NotificationList, BlindBrick } from 'components';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { useClass } from 'store/class';
import {
  UiHeader,
  UiHeaderText,
  UiClassName,
  UiNotice,
  UiNoticeNew,
  UiIsLogin,
} from './Header.style';

/**
 * Header
 */

// eslint-disable-next-line react/prop-types
export const Header = ({ isNeedLogo = true }) => {
  const [{
    isOpen,
    isNew,
  }, setState] = useSetState({
    isOpen: false,
    isNew: false,
  });
  const invModalToggle = () => {
    setState({
      isOpen: !isOpen
    });
  };

  const { organizationId, classId } = useParams();
  const { pathname } = useLocation();
  const [{ profile, myOrganization }, { fetchOrganizationById }] = useUser();
  const [{ myClasses }, { getGroupInfoById }] = useClass();
  const { dataInfo: myClassInfo } = myClasses;

  const isLogin = profile?.id;
  const customOrganizationLogoUrl = myOrganization?.organization?.customOrganizationLogoUrl;

  const getNewState = (isNew) => {
    setState({
      isNew,
    });
  };

  useEffect(() => {
    if (!classId) return;
    getGroupInfoById(classId);
  }, [classId]);

  useEffect(() => {
    if (!organizationId) return;
    fetchOrganizationById(organizationId);
  }, [organizationId]);

  useEffect(() => {
    console.log('pathname', pathname);
  }, []);

  return (
    <UiHeader isNeedLogo={isNeedLogo}>
      <BlindBrick className='accesskey' href="#U" id="U" title="上方功能區塊" accessKey="U" name="U" />
      <div className="container">
        {
          isNeedLogo && <h1 className="logo">
            <Link to="/home">
              <img src={pathname === '/home' ? logo : customOrganizationLogoUrl || logo} alt="嘉義市政府教育處特殊教育課室管理系統，點擊可回到首頁" />
            </Link>
          </h1>
        }
        {
          (isLogin && pathname !== '/home') && <Breadcrumbs className="breadcrumbs" >
            {
              organizationId && <div className="breadcrumbItem">
                <UiHeaderText tabIndex={0}>
                  <Icon.Svg name="School" />
                  {myOrganization?.organization?.officialName || ''}
                </UiHeaderText>
              </div>
            }
            {
              myClassInfo?.name &&
              <Typography color="textPrimary">
                <UiHeaderText>
                  <UiClassName>{myClassInfo?.name}</UiClassName>
                </UiHeaderText>
              </Typography>
            }
          </Breadcrumbs>
        }
        <UiIsLogin >
          {
            isLogin && <>
              <UiNotice tabIndex={0} aria-label="加入班級通知" onClick={invModalToggle}>
                <Icon.Svg name="Notice" />
                {isNew && <UiNoticeNew></UiNoticeNew>}
                {isLogin && <NotificationList onClick={invModalToggle} isOpen={isOpen} getNewState={getNewState} />}
              </UiNotice>
            </>
          }
          <AvatarMenu />
        </UiIsLogin>
      </div>
    </UiHeader >
  );
};

import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { TextField as MatTextField, Box } from '@material-ui/core';
import { useUser } from 'store/user';
import MatImage from 'material-ui-image';
import homeworkLate from 'assets/images/late.svg';
import homeworkDone from 'assets/images/done.svg';
import JSZip from 'jszip';
import {
  Button,
  Icon,
  Card,
  ClickablePopoverMenu,
  Modal,
  UploadButton,
  HomeworkCardEdit,
  AlbumSlider,
} from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { EDIT_STATUS, HOMEWORK_SETTINGS_VALUE } from 'constants/index';
import { formatTimestamp } from 'utils/date';

import {
  UiCardHead,
  UiContentTitle,
  UiContent,
  UiCardFooter,
  UiTeacherInfo,
  UiUserName,
  UiCreateDate,
  LabelGroup,
  Label,
  LabelValue,
  UiStudentHomeworkForm,
  LabelInnerGroup,
  LabelModal,
  LabelClose,
  LabelModalContent,
  StudentModalContent,
  StudentModalTitle,
  StudentModalHomeworkTitle,
  StudentModalHomeworkContent,
  UiContentTitleLeft,
  UiContentTitleRight,
  UiHomeWorkSign,
  UiAttachmentsBox,
  UiButtonBox,
} from './HomeworkCardRead.style';


/**
 * 作業卡牌組件
 */


export const HomeworkCardRead = ({
  data,
  userType = 'student',
  isAdmin = false,
  isLoading,
  onDelete = () => { },
  onPin = () => { },
  onCancelPin = () => { },
  onCardReadFormSubmit = () => { },
  onUploaded = () => { },
}) => {
  const [{ profile: { id: userId } }] = useUser();
  const history = useHistory();
  const { organizationId, classId } = useParams();
  const {
    id,
    ownerName,
    formatPublishedAt,
    formatDueAt,
    title,
    description,
    attachments,
    number,
    total,
    isExpired,
    canOperating,
    accessLevel,
    canEdit,
    isPinned,
    submissions,
    dueAt
  } = data;
  const [{
    isModalOpen,
    cardStatus,
    showHomeworkForm,
    fileModal,
    commentModal,
    submitStudentHomeworkData,
    sliderState,
    initSlide,
    comment,
    file,
    filesLimit,
    subAttachments,
    albumType,
    downloadLoading,
  }, setState] = useSetState({
    isModalOpen: false,
    cardStatus: null,
    showHomeworkForm: false,
    fileModal: false,
    commentModal: false,
    submitStudentHomeworkData: null,
    sliderState: false,
    initSlide: 0,
    comment: '',
    file: [],
    filesLimit: 10,
    subAttachments: [],
    albumType: '',
    downloadLoading: false
  });

  const homeworkInputRef = useRef();

  const deleteModalConfig = {
    text: '是否要將此項作業刪除？',
    confirmAction: () => onDelete(data)
  };

  const pinModalConfig = {
    text: isPinned ? '是否要將此項作業取消置頂？' : '是否要將此項作業置頂？',
    confirmAction: () => isPinned ? onCancelPin(data) : onPin(data)
  };

  const submitModalText = () => {
    if (!submissions || !submissions[userId]) return;
    const { submittedAt } = submissions[userId];
    return (<StudentModalContent tabIndex={0}>
      <StudentModalTitle>作業</StudentModalTitle>
      <StudentModalHomeworkTitle>{title}</StudentModalHomeworkTitle>
      <StudentModalHomeworkContent>
        <div>已於{formatTimestamp(submittedAt, 'yyyy-MM-dd HH:mm:ss')}繳交</div>
        <div>重複繳交僅會保留最後一次的繳交資料</div>
        <div>確認是否仍要重複繳交作業？</div>
      </StudentModalHomeworkContent>
    </StudentModalContent>);
  };

  const submitConfig = {
    content: submitModalText(data),
    confirmAction: () => onHomeworkFormSubmitHandler(submitStudentHomeworkData, 'modal')
  };

  const setModalConfig = () => {
    switch (cardStatus) {
      case EDIT_STATUS.DELETE:
        return deleteModalConfig;
      case EDIT_STATUS.SUBMIT:
        return submitConfig;
      default:
        return pinModalConfig;
    }
  };


  const ModalConfig = setModalConfig();

  const buttons = [
    {
      text: '確認',
      func: ModalConfig.confirmAction
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  const setCardStatus = status => () => setState({ cardStatus: status, isModalOpen: status !== EDIT_STATUS.EDIT });

  let popoverMenuList = [
    { label: '刪除', func: setCardStatus(EDIT_STATUS.DELETE), iconName: 'delete' },
  ];

  if (isAdmin) {
    popoverMenuList = [
      {
        label: isPinned ? '取消置頂' : '置頂',
        func: setCardStatus(EDIT_STATUS.ALIGN_TOP),
        iconName: 'Thumbtack',
        isSvg: true,
      }, ...popoverMenuList];
  }

  if (canEdit) {
    popoverMenuList = [
      {
        label: '編輯',
        func: setCardStatus(EDIT_STATUS.EDIT),
        iconName: 'edit'
      }
      , ...popoverMenuList];
  }

  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  const doShowHomeworkForm = useCallback((isExpired) => {
    if (isExpired) return;
    setTimeout(() => {
      homeworkInputRef.current && homeworkInputRef.current.focus();
    }, 1000);
    setState({
      showHomeworkForm: !showHomeworkForm
    });
  }, [homeworkInputRef]);

  const onHomeworkFormSubmitHandler = (value, type = '') => {
    if (submissions) {
      if (submissions[userId] && type !== 'modal') {
        setState({
          submitStudentHomeworkData: value,
          cardStatus: EDIT_STATUS.SUBMIT,
          isModalOpen: true,
        });
        return;
      }
    }
    const payload = {
      data,
      comment,
      file,
      attachments: subAttachments
    };

    onCardReadFormSubmit(payload);
  };

  const goSubmitted = () => {
    history.push(organizationId ?
      `/home/${organizationId}/class/${classId}/homeworks/${id}/submitted` :
      `/home/class/${classId}/homeworks/${id}/submitted`);
  };

  const CardHeader = (
    <UiCardHead>
      <UiTeacherInfo>
        <UiUserName>
          <span>{ownerName}</span>
          {isPinned && <Icon.Svg name="Thumbtack" color="#EC7963" haveBg={false} />}
        </UiUserName>
        <UiCreateDate>{formatPublishedAt}</UiCreateDate>
      </UiTeacherInfo>
      {(userType === 'teacher') ?
        <Button
          onClick={goSubmitted}
          buttonColor={(accessLevel === 'owner' && !canOperating) ? 'disable' : 'new'}
          disabled={accessLevel === 'owner' && !canOperating}
        >繳交狀況</Button> :
        <Button
          onClick={() => doShowHomeworkForm(isExpired)}
          onKeyDown={(e) => e.key === 'Enter' && doShowHomeworkForm(isExpired)}
          buttonColor={isExpired ? 'disable' : 'new'}
          disabled={isExpired}
        >
          繳交作業
        </Button>
      }
    </UiCardHead>
  );

  const onChangeHandle = (value, key) => {
    setState({
      [key]: value
    });
  };

  const onSubMissionFileDelete = (target) => {
    const newAttachments = subAttachments.filter(attachment => attachment.attachmentUrl !== target);
    setState({
      subAttachments: newAttachments
    });
  };

  useEffect(() => {
    if (!submissions) return;
    const targetSubmissions = submissions[userId];
    setState({
      comment: targetSubmissions.comment,
      subAttachments: targetSubmissions.attachments,
    });
  }, [userId]);

  const CardFooter = (
    <>
      <UiCardFooter>
        <LabelGroup>
          <LabelInnerGroup>
            <Label>截止日期</Label>
            <LabelValue>{formatDueAt}</LabelValue>
          </LabelInnerGroup>
          {
            (submissions && submissions[userId]) &&
            <LabelInnerGroup>
              <LabelValue>{formatTimestamp(submissions[userId].submittedAt, 'yyyy-MM-dd HH:mm:ss')}</LabelValue>
              <LabelValue>第{submissions[userId].submitCount}次繳交</LabelValue>
              <LabelValue>
                {
                  fileModal &&
                  <LabelModal>
                    <LabelModalContent>
                      <LabelClose
                        onClick={() => { setState({ fileModal: false }); }}>
                        <Icon name='close' size='small' haveBg={false} />
                      </LabelClose>
                      {
                        submissions[userId].attachments.map(item => {
                          return (
                            <a className="item"
                              key={item.attachmentUrl}
                              href={item.attachmentUrl}
                              download
                              target="_blank"
                              rel="noreferrer">
                              <Icon name="getApp" haveBg={false} />
                              <span>
                                {item.attachmentName}
                              </span>
                            </a>
                          );
                        })
                      }
                    </LabelModalContent>
                  </LabelModal>
                }

                <Button
                  onClick={() => { setState({ fileModal: !fileModal }); }}
                  buttonColor={submissions[userId].attachments.length > 0 ? 'normal' : 'disable'}
                  disabled={submissions[userId].attachments.length === 0}>附檔</Button>
              </LabelValue>
              <LabelValue>
                {
                  commentModal &&
                  <LabelModal>
                    <LabelModalContent>
                      <LabelClose
                        onClick={() => { setState({ commentModal: false }); }}>
                        <Icon name='close' size='small' haveBg={false} />
                      </LabelClose>
                      {submissions[userId].comment}
                    </LabelModalContent>
                  </LabelModal>
                }
                <Button
                  onClick={() => { setState({ commentModal: !commentModal }); }}
                  buttonColor={submissions[userId].comment ? 'normal' : 'disable'}
                  disabled={!submissions[userId].comment}
                >留言
                </Button>
              </LabelValue>
            </LabelInnerGroup>
          }
        </LabelGroup>
        <LabelGroup>
          <Label>繳交人數</Label>
          <LabelValue>{`${number}/${total}`}</LabelValue>
        </LabelGroup>
        {(accessLevel === 'group' || canOperating) &&
          <ClickablePopoverMenu menuList={popoverMenuList}>
            <Icon haveBg={false} name="moreHoriz" icolor="#000"></Icon>
          </ClickablePopoverMenu>
        }
      </UiCardFooter>
      {showHomeworkForm &&
        <UiStudentHomeworkForm>
          <Box p={2}>
            <MatTextField
              inputRef={homeworkInputRef}
              label="請輸入作業內容"
              placeholder='請輸入作業內容'
              variant="outlined"
              multiline={true}
              rows={3}
              style={{ width: '100%' }}
              value={comment}
              onChange={e => onChangeHandle(e.target.value, 'comment')}
            />
          </Box>
          {subAttachments.length > 0 &&
            <UiAttachmentsBox>
              <span>繳交附件列表 :</span>
              {
                subAttachments.map((att, index) => {
                  return (
                    <div className="item" key={att.attachmentUrl}>
                      <span onClick={() => openAlbumSlider(true, index, 'subMission')}>{att.attachmentName}</span>
                      <button aria-label='刪除' onClick={() => onSubMissionFileDelete(att.attachmentUrl)}>
                        <Icon haveBg={false} icolor={'#000'} name="delete"/>
                      </button>
                    </div>
                  );
                })
              }
            </UiAttachmentsBox>}
          <Box p={2}>
            <UploadButton
              label="附加檔案"
              onChange={files => onChangeHandle(files, 'file')}
              filesLimit={filesLimit - subAttachments.length - file.length}
            />
          </Box>
          <UiButtonBox>
            <Button
              onClick={() => doShowHomeworkForm(isExpired)}
              buttonColor="ui03"
              textColor="#3a4052"
              loading={isLoading}>
              取消
            </Button>
            <Button onClick={onHomeworkFormSubmitHandler} loading={isLoading}>送出作業</Button>
          </UiButtonBox>
        </UiStudentHomeworkForm>
      }

    </>
  );
  const onSubmitHandler = val => {
    const { id } = data;
    const nextData = {
      id,
      ...val,
      isLateSubmissionAllowed: val.isLateSubmissionAllowed || false,
      accessLevel: val.accessLevel || HOMEWORK_SETTINGS_VALUE.OWNER,
      file: val.file || []
    };
    onUploaded(nextData, nextData.file);
  };

  const closeHandler = e => {
    if (!e) {
      setState({
        sliderState: false
      });
      return;
    }
    if (e.currentTarget !== e.target) {
      e.stopPropagation();
      return;
    }
    setState({
      sliderState: false
    });
  };

  const openAlbumSlider = (state, index, type) => {
    setState({
      sliderState: state,
      initSlide: index,
      albumType: type
    });
  };

  const changeCardStateHandler = () => {
    setState({
      cardStatus: EDIT_STATUS.READ
    });
  };

  const downloadZip = async (data, fileName) => {
    setState({
      downloadLoading: true
    });
    let uploadFile = null;
    let uploadFileName = '';
    if (data.length > 1) {
      const zip = new JSZip();
      await Promise.all(data.map(async item => {
        const fileBlob = await fetch(item.attachmentUrl).then(res => res.blob());
        zip.file(item.attachmentName, fileBlob);
      }));

      uploadFile = await zip.generateAsync({ type: 'blob' }).then(content => {
        return content;
      });
      uploadFileName = `${fileName}.zip`;
    } else {
      uploadFile = await fetch(data[0].attachmentUrl).then(res => res.blob());
      uploadFileName = data[0].attachmentName;
    }


    const url = window.URL.createObjectURL(new Blob([uploadFile]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', uploadFileName);
    document.body.appendChild(link);
    link.click();
    setState({
      downloadLoading: false
    });
  };

  return (
    <>
      {
        cardStatus === EDIT_STATUS.EDIT ?
          <HomeworkCardEdit
            type={cardStatus}
            data={data}
            isOpen={true}
            onSubmit={onSubmitHandler}
            isLoading={isLoading}
            changeCardState={() => changeCardStateHandler()}
          /> :
          <>
            <Card header={CardHeader} footer={CardFooter}>
              <UiContentTitle>
                <UiContentTitleLeft>
                  {title}
                </UiContentTitleLeft>
                {
                  (submissions && submissions[userId]) &&
                  <UiContentTitleRight>
                    <UiHomeWorkSign isLate={submissions[userId].submittedAt > dueAt}>
                      <MatImage src={submissions[userId].submittedAt > dueAt ? homeworkLate : homeworkDone} alt="org" />
                    </UiHomeWorkSign>
                  </UiContentTitleRight>

                }
              </UiContentTitle>
              <UiContent dangerouslySetInnerHTML={{ __html: description }}></UiContent>
              {attachments.length > 0 &&
                <UiAttachmentsBox>
                  <span>附件列表 :</span>
                  <Button
                    loading={downloadLoading}
                    onClick={() => downloadZip(attachments, `${title}_作業附件`)}
                  >
                    下載全部附件
                  </Button>
                  {
                    attachments.map((att, index) => {
                      return (
                        <div className="item" key={att.attachmentUrl}>
                          <span
                            onClick={() => openAlbumSlider(true, index, 'mission')}>{att.attachmentName || '無檔案名稱'}</span>
                        </div>
                      );
                    })
                  }
                </UiAttachmentsBox>}
            </Card>
            <Modal
              isOpen={isModalOpen}
              text={ModalConfig.text}
              content={ModalConfig.content || ''}
              buttons={buttons}
              getModalState={getModalStateHandler}
            />
            {
              attachments.length > 0 &&
              <AlbumSlider
                isOpen={sliderState}
                close={closeHandler}
                defaultData={{ attachments: albumType === 'mission' ? attachments : subAttachments }}
                initalSlide={initSlide}
                type="slider"
              />
            }
          </>
      }

    </>
  );
};

HomeworkCardRead.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    userName: PropTypes.string,
    createData: PropTypes.string,
    contentTitle: PropTypes.string,
    content: PropTypes.string,
    deadline: PropTypes.string,
    number: PropTypes.number,
    total: PropTypes.number,
    attachments: PropTypes.array
  }),
  userType: PropTypes.string,
  onDelete: PropTypes.func,
  onPin: PropTypes.func,
  onCardReadFormSubmit: PropTypes.func,
  onUploaded: PropTypes.func,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool
};

import { useCallback } from 'react';
import { useFirebase } from 'utils/hooks/useFirebase';

export const useFirebaseStorage = () => {
  const { storage } = useFirebase();

  const storgeRef = useCallback(() => {
    return storage.ref();
  }, [storage]);

  const upload = async (uploadPath, file) => {
    try {
      const snapshot = await storgeRef().child(uploadPath).put(file);
      const url = await storgeRef().child(uploadPath).getDownloadURL();
      return {
        status: true,
        data: snapshot,
        url
      };
    } catch (error) {
      return {
        status: false,
        data: error
      };
    }
  };

  return { upload };
};

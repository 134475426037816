import styled from 'styled-components';

export const UiHomeAnnouncementTable = styled.div`
	display: block;
`;

export const UiTitleBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-size: 20px;
	margin-bottom: 1.5rem;

	button {
		margin: 0;
	}
`;

export const UiTitle = styled.div`
	font-size: 20px;
	line-height: 30px;
	color: #242c3f;
`;

export const UiCardBox = styled.div`
	margin: 1rem 0;
`;

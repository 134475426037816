import styled from 'styled-components';

export const UiGroupCard = styled.div`
	position: relative;
	overflow: hidden;
	width: calc(24% - 24px);
	border-radius: 8px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: 0.35s;
	cursor: pointer;

	& > div {
		padding: 15px;
		color: #454b5c;
		letter-spacing: 2px;
		line-height: 1.5;

		& > div:nth-child(2) {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: #242c3f;
		}
	}

	&:hover {
		box-shadow: 0 6px 7px rgba(0, 0, 0, 0.2), 0 5px 6px rgba(0, 0, 0, 0.17);
	}
`;

export const UiGroupsImage = styled.div`
	width: 100%;
	height: 200px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #eee;
	background-image: ${(props) => `url(${props.url}) `};
`;

export const UiRoleIcon = styled.div`
	position: absolute;
	top: 12px;
	right: 12px;
	padding: 0 !important;

	> .tag {
		padding: 2px 8px;
		font-size: 16px;
		color: #fff;
		background: #121232;
		border-radius: 4px;
		line-height: 24px;
		font-weight: 400;
	}
`;

export const UiGroupsPrompt = styled.div`
	margin: 0.25rem 0 1rem;
	padding: 15px;
	width: 100%;
	font-size: 1.3rem;
	color: #f00;
`;

import styled from 'styled-components';

export const UiAuthorityTable = styled.div`
	display: block;
	padding: 20px;
`;

export const UiFilterBox = styled.div`
	display: flex;
	margin-bottom: 20px;
`;

export const UiFilterBoxLeft = styled.div`
	display: flex;
	align-items: center;
	width: 50%;
	height: 100%;

	& > div:first-child {
		margin-right: 0.5rem;
	}
`;

export const UiFilterBoxRight = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 50%;
`;

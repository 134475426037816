import styled from 'styled-components';

export const UiHomeworkSubmittedStudentTable = styled.div`
	display: block;
`;

export const UiTableTop = styled.div`

`;

export const UiAttachmentLink = styled.a`
	font-weight: bold;
`;

export const UiTableTopButton = styled.div`
margin-bottom: 2rem;

& > div {
	justify-content: flex-start;
}
`;

export const UiTableTopSearch = styled.div`
	margin-bottom: 1rem;

	& > div {
		justify-content: flex-start;
	}

	& > div > * {
		flex: 1;
	}
`;

export const UiflexDefault = styled.div`
	flex: inherit !important;
`;

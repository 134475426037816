import React from 'react';
import PropTypes from 'prop-types';
import { Header, SessionGroup, BlindBrick } from 'components';
import { UiSingleLayout, UiSingleContent, UiHelloTitle } from './SingleLayout.style';
import sun from 'assets/images/sun.png';
import moon from 'assets/images/moon.png';
import cloud2 from 'assets/images/cloud2.png';

/**
 * 沒有側邊欄的版型
 */

const GREETINGS = {
  MORNING: '早安',
  AFTERNOON: '午安',
  NIGHT: '晚安'
};
const getTimeState = () => {
  const nowHour = new Date().getHours();
  if (nowHour >= 0 && nowHour <= 12) {
    return GREETINGS.MORNING;
  } else if (nowHour > 12 && nowHour <= 18) {
    return GREETINGS.AFTERNOON;
  } else if (nowHour > 18 && nowHour <= 24) {
    return GREETINGS.NIGHT;
  }
};
const getTimeImg = () => {
  const nowHour = new Date().getHours();
  if (nowHour >= 0 && nowHour <= 12) {
    return sun;
  } else if (nowHour > 12 && nowHour <= 18) {
    return sun;
  } else if (nowHour > 18 && nowHour <= 24) {
    return moon;
  }
};
export const SingleLayout = ({ children, showLeftSidebar = true }) => {
  return (
    <UiSingleLayout>
      <UiSingleContent showLeftSidebar={showLeftSidebar} cloud2={cloud2}>
        <div className="header">
          <Header isNeedLogo={true} />
        </div>
        { showLeftSidebar && <div className="leftSidebar">
          <BlindBrick className='accesskey' id='left_container' accessKey='l' href='#left_container' title='左方選單區塊' />
          <UiHelloTitle>
            <div>
              <img src={getTimeImg()} alt='' />
            </div>
            <h1 className='greetings' tabIndex={0}>{getTimeState()} !</h1>
          </UiHelloTitle>
          <div className="main">
            <div className="subTitle">
              <h3 tabIndex={0}>今日課程</h3>
            </div>
            <SessionGroup />
            {/* <a className="wtfIssue" 
                    href="https://oneask.oneclass.com.tw/index.html" target="_blank" rel="noreferrer">
              <img src={oneask} alt="oneAsk" />
            </a> */}
          </div>
        </div>}
        <div className="main">
          {children}
        </div>
      </UiSingleContent>
    </UiSingleLayout>

  );
};

SingleLayout.propTypes = {
  children: PropTypes.node,
};



import React from 'react';
import PropTypes from 'prop-types';
import lrz from 'lrz';
import { Modal, DropzoneUploader, Icon, AlbumSlider, } from 'components';
import { useSetState } from 'utils/hooks/useSetState';

import { isExist } from 'utils/helper';
import { UiClickableUpload, UiUploadFileName,UiFileList } from './ClickableUpload.style';


/**
 * 可使被套用的組件點擊後彈出上傳視窗
 */

const FileList = ({ fileName, onDelete,onClick }) => {

  return (
    <UiUploadFileName>
      <span onClick={onClick}>
        {fileName}
      </span>
      <button aria-label='刪除' onClick={onDelete}>
        <Icon haveBg={false} icolor={'#000'} name="delete"/>
      </button> 
    </UiUploadFileName>
  );
};

FileList.propTypes = {
  fileName: PropTypes.string,
  onDelete: PropTypes.func,
  onClick:PropTypes.func
};

export const ClickableUpload = ({
  children,
  onUploaded,
  isShowFileList = false,
  fileUrl = '',
  defaultValue = [],
  acceptedFiles = [],
  filesLimit = 1,
}) => {

  const [{ isOpen, file,newFiles, sliderState, initSlide,previews,newPreviews }, setState] = useSetState({
    isOpen: false,
    file: [],
    sliderState:false,
    initSlide:0,
    previews:[],
    newFiles:[],
    newPreviews:[]
  });

    // 修改相簿狀態
  const openAlbumSlider = (state, index) => {
    setState({
      sliderState: state,
      initSlide: index,
    });
  };

  const closeHandler = e => {
    if (!e) {
      setState({
        sliderState: false
      });
      return;
    }
    if (e.currentTarget !== e.target) {
      e.stopPropagation();
      return;
    }
    setState({
      sliderState: false
    });
  };

  const deleteFile = index => () => {
    if(isExist(index)) {
      const nextFiles = file.filter((item,i) => i !== index );
      setState({
        file: nextFiles,
        previews: previews.filter((item,i) => i !== index )
      });
      onUploaded(nextFiles);
    } else {
      setState({ file: [], previews:[] });
    }
  };

  const onUploadedHandle = () => {
    if(newFiles.length === 0) return;
    const nextFile = file.concat(newFiles);
    const nextPreviews = previews.concat(newPreviews);
    setState({
      file : nextFile,
      newFiles: [],
      newPreviews: [],
      previews: nextPreviews
    });
    onUploaded(nextFile);
  };

  const actionButtons = [
    {
      text: '取消',
      color: 'cancel',
      func: deleteFile
    },
    {
      text: '確認',
      color: 'new',
      func: () => onUploadedHandle()
    },
  ];

  const onClickHandler = () => {
    setState({ isOpen: true });
  };

  const getModalStateHandler = state => {
    setState({ isOpen: state });
  };

  const onUploadHandler = newFiles => {
    Promise.all(newFiles.map(newFile => {
      if(newFile.type.split('/')[0] === 'image'){
        return lrz(newFile).then(res => {
          let attachment = {
            attachmentName: newFile.name,
            attachmentType: newFile.type,
          };
          attachment.attachmentUrl = res.base64;
          for (let value of res.formData.values()) {
            attachment.value = value;
          }
          return attachment;
        }).catch(res=>{
          return {
            attachmentName: newFile.name,
            attachmentType: newFile.type,
            attachmentUrl:''
          };
        });
      }else{
        return {
          attachmentName: newFile.name,
          attachmentType: newFile.type,
          attachmentUrl:''
        };
      }
    })).then(res=>{
      setState({
        newFiles: newFiles,
        newPreviews : res
      });
    });
  };

  const contentComponent =
    <DropzoneUploader acceptedFiles={acceptedFiles} onChange={onUploadHandler} filesLimit={filesLimit} />;


  return (
    <>
      <UiClickableUpload onClick={onClickHandler}>
        {children}
      </UiClickableUpload>
      {
        isShowFileList && file.length > 0 && (
          <UiFileList>
            {
              Array.isArray(file) ? (
                file.map((item, index) => (
                  <FileList
                    key={item.name + index}
                    fileName={item.name}
                    onDelete={deleteFile(index)}
                    onClick={() => openAlbumSlider(true,index)}
                  />
                ))
              ) : (
                <FileList fileName={file.name} onDelete={deleteFile(null)}/>
              )
            }
          </UiFileList>
        )
      }
      {
        (fileUrl !== '' && !file) &&
        <UiUploadFileName>
          <a href={fileUrl} target='_blank' rel="noopener noreferrer">查看附件</a>
        </UiUploadFileName>
      }
      <Modal
        isIconShow={false}
        isOpen={isOpen}
        content={contentComponent}
        buttons={actionButtons}
        getModalState={getModalStateHandler}
      />
      {
        Array.isArray(file) ?
        <AlbumSlider
          isOpen={sliderState}
          close={closeHandler}
          defaultData={{ attachments:previews }}
          initalSlide={initSlide}
          type="slider"
        /> : ''
      }
    </>

  );
};

ClickableUpload.propTypes = {
  children: PropTypes.node,
  onUploaded: PropTypes.func,
  isShowFileList: PropTypes.bool,
  fileUrl: PropTypes.string,
  defaultValue: PropTypes.array,
  acceptedFiles: PropTypes.array,
  filesLimit: PropTypes.number
};



import { Api } from 'services/api';
import { checkNaniOneClass, sendData, logoutNaniOneClass } from './login';


/**
 * 取得 user profile
 */
export const getProfile = () => Api.get('/users/me');

/**
 * 登入
 * @param {string} username
 * @param {string} password
 * @returns {object} user profile
 */
export const login = async ({ username, password }) => {
  const { code, jwt, message } = await sendData(username, password);

  if (code !== 'SUCCESS') return { error: message };//throw new Error(message);
  const token = JSON.parse(jwt).jwt;
  Api.setToken(token);

  const profile = await getProfile();
  return profile;
};

/**
 * 登出
 */
export const logout = () => logoutNaniOneClass();

/**
 * 取得登入狀態
 * @param {function} callback
 */
export const checkLogin = async () => {
  let token = await new Promise(resolve => {
    checkNaniOneClass(result => { resolve(result); });
  });
  if (!token) {
    token = getLocalToken() && JSON.parse(getLocalToken()).jwt;
  }
  Api.setToken(token);

  return token ? true : false;
};


/**
 * 取得存在 localStorage 的 token
 */
const getLocalToken = () => {
  if (localStorage['nani_cool_token']) {
    const nani_cool_token = localStorage['nani_cool_token'];
    return nani_cool_token;
  }
  return '';
};


import styled from 'styled-components';

export const UiOrganizationContainer = styled.div`
	margin: 3rem 0 4rem;
`;

export const UiHead = styled.div`
	display: flex;
	align-items: center;
	padding: 16px 24px;
	background-color: #f2f4f8;
	margin-bottom: 30px;
	border-radius: 4px;

	& > span {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		font-weight: 400;
		line-height: 30px;
		color: #242c3f;

		& > svg {
			margin-right: 18px;
		}
	}

	& > span:last-of-type {
		margin-right: auto;
	}

	& > button {
		margin-left: 8px;
	}
`;

export const UiGroupsBox = styled.div`
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;

	& > div {
		margin-bottom: 20px;
	}

	& > div:not(:nth-child(4n + 1)) {
		margin-left: 24px;
	}
`;


import { format } from 'date-fns';

export const timestampFormatStr = (timestamp, breakline = '') => {
  const now = new Date(timestamp);
  const year = now.getFullYear();
  const month = fillLeadingZero(now.getMonth() + 1);
  const day = fillLeadingZero(now.getDate());
  return year + breakline + month + breakline + day;
};



const fillLeadingZero = (str) => {
  str = '0' + str;
  return str.slice(str.length - 2, str.length);
};


export const formatTimestamp = (timestamp, timeStr) => {
  //format(new Date(submissions[userId].submittedAt), 'yyyy-MM-dd HH:mm:ss')
  return format(new Date(timestamp), timeStr);
};

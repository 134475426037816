import styled from 'styled-components';

export const UiAccountTable = styled.div`
	display: block;
	padding: 20px;
`;

export const UiFilterBox = styled.div`
	display: flex;
	margin-bottom: 20px;
`;

export const UiFilterBoxLeft = styled.div`
	display: flex;
	align-items: center;
	height: 100%;

	& > * {
		margin-right: 0.5rem;
	}
`;


import styled from 'styled-components';

export const UiEmptyPrompt = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
`;

export const UiTitle = styled.h3.attrs(() => ({ tabIndex: '0' }))`
	font-size: 1.25rem;
	color: ${({ titleColor }) => titleColor ? titleColor : '#3A4052'}
`;

export const UiMessage = styled.p.attrs(() => ({ tabIndex: '0' }))`
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
	margin: 0;
	color: ${({ messageColor }) => messageColor ? messageColor : '#3A4052'}
`;
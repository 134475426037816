import styled from 'styled-components';

export const UiPhoneFormWrapper = styled.div``;

export const UiPhoneForm = styled.div`
	display: flex;
	align-items: flex-end;
`;

export const UiHelperText = styled.div`
	& p {
		position: relative;
		left: 50%;
		color: #f44336;
	}
`;

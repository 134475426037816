import styled from 'styled-components';

export const UiCitySelector = styled.div`
	display: ${({ isFullWidth }) => isFullWidth ? 'block' : 'flex'};

	justify-content: space-between;
	width: 100%;

	& > div {
		width: ${({ isFullWidth }) => isFullWidth ? '100%' : '49%'};
	}

	& > div:first-child {
		margin-bottom: ${({ isFullWidth }) => isFullWidth ? '1.5rem' : '0'};
	}
`;

export const UiSelector = styled.div`
	& div::before {
		border-bottom: 1px solid ${({ active }) => active ? '#f44336' : '#000'};
	}

	& label {
		color: ${({ active }) => active ? '#f44336' : '#000'};
	}
`;

export const UiHelperText = styled.div`
	& p {
		color: #f44336;
	}
`;

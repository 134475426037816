import styled from 'styled-components';

export const UiLatestNews = styled.div`
	position: absolute;
	top: 40px;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	padding: 0.7rem;
	padding-right: 10px;
	width: max-content;
	color: #fff;
	background-color: #ec7963;
	border-radius: 25px;
	z-index: 9999;
	cursor: pointer;

	> div {
		margin-right: 2px;
	}

	> svg {
		margin-right: 4px;
		font-size: 1rem;

		path {
			fill: #fff;
		}
	}

	&:hover {
		background-color: #ee8773;
	}
`;

import styled from 'styled-components';

export const UiPostCardEdit = styled.div`
	display: block;
	width: 100%;

	& > form {
		padding: 0;

		& > div {
			justify-content: flex-end;
			margin: 0;
		}
	}

	.MuiOutlinedInput-root {
		border-radius: 0;

		.MuiOutlinedInput-notchedOutline {
			border: 1px solid #d8edf0;
		}
	}
`;


export const UiDateTitle = styled.div`
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	font-weight: bolder;
	color: #163e6f;
	background-color: #f4f3f0;
	border: 1px solid #d8edf0;
	border-bottom: none;
`;

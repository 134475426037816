import styled from 'styled-components';

export const UiAlbumTable = styled.div`
	display: block;
`;

export const UiAlbumTableHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
`;

export const UiAlbumTableTitle = styled.div`
	font-size: 20px;
	line-height: 30px;
	color: #242c3f;
`;

export const UiModal = styled.div`
	& > div {
		margin: 1rem 0;
	}
`;

export const UiModalInput = styled.div`
	margin: 1rem 0;

	& > div {
		width: 100%;
	}
`;

export const UiAlbumList = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;

	& > div {
		width: 19%;

		&:not(:nth-child(5n + 1)) {
			margin-left: 1.25%;
		}
	}
`;

export const UiHelperText = styled.div`
	color: #c7957a;
`;

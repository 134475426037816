import { Api } from 'services/api';
// import { stringify } from 'query-string';

// 取得 OnePaper List
// export const getPaperList = async (params) => {
//   const { year, paperToken } = params;
//   const queryParams = {
//     year
//   };
//   const querystring = stringify(queryParams);
//   return fetch(`${process.env.REACT_APP_ONEPAPER_API_DOMAIN}/api/Exam/Query?${querystring}`,{
//     headers: {
//       authorization:paperToken
//     }
//   }).then(res=>res.json());
// };

export const getOnePaper = (groupId, year) => {
  const response = Api.get(`/groups/${groupId}/one-paper?year=${year}`);
  return response;
};
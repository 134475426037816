import styled from 'styled-components';

export const UiOrganizationForm = styled.div`
	display: flex;
	padding: 20px;
	flex-direction: initial !important;

	.right {
		margin-left: 40px;
		flex: 1;
	}
`;


export const UiButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	& > button + button {
		margin-left: 0.5rem;
	}
`;
export const UiFilterBox = styled.div`
	display: flex;
	margin-bottom: 20px;
`;

export const UiButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	& > button {
		margin: 0.5rem 0;
	}

	& > button + button {
		margin: 0.5rem 0.5rem 0.5rem;
	}
`;

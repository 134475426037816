import styled from 'styled-components';

export const UiLoginBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;
	//background-color: #fff;
	background: #F2F4F8;
    overflow-x: hidden;
`;

import styled from 'styled-components';

export const UiSchoolCitySelector = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	width: ${({ width }) => width && width};
`;

export const UiSchoolSelect = styled.div`
	width: ${({ width }) => width && width};

	& div::before {
		border-bottom: 1px solid ${({ active }) => active ? '#f44336' : '#000'};
	}

	& label {
		color: ${({ active }) => active ? '#f44336' : '#000'};
	}
`;

export const UiHelperText = styled.div`
	& p {
		color: #f44336;
	}
`;

export const UieducationSelect = styled.div`
	width: ${({ width }) => width};

	& div::before {
		border-bottom: 1px solid ${({ active }) => active ? '#f44336' : '#000'};
	}

	& label {
		color: ${({ active }) => active ? '#f44336' : '#000'};
	}
`;

export const UiSchoolBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 0.5rem;
	width: 100%;

	& > span {
		font-size: 1.5rem;
	}

	& > button {
		width: 100%;
	}
`;

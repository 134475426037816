// import { stringify } from 'query-string';
import { Api } from 'services/api';

const baseUrl = process.env.REACT_APP_VIDEO_BANK_API_DOMAIN;


export const getTeacherAssignedVideoRecord = async (params) => {
  const { classId, teacherId } = params;
  const response = await Api.get(`${baseUrl}/courses/getTeacherAssignedVideoRecord/${classId}/${teacherId}`);
  return response;
};

export const getStudentVideoRecord = async (params) => {
  const { classId, teacherId, studentId } = params;
  const response = await Api.get(`${baseUrl}/courses/getStudentVideoRecord/${classId}/${teacherId}/${studentId}`);
  return response;
};

export const getStudentAllVideoRecord = async (params) => {
  const { classId, studentId } = params;
  const response = await Api.get(`${baseUrl}/courses/getStudentAllVideoRecord/${classId}/${studentId}`);
  return response;
};

export const getVideoRecord = async (params) => {
  const { classId, teacherId, videoId } = params;
  const response = await Api.get(`${baseUrl}/courses/getVideoRecord/${classId}/${teacherId}/${videoId}`);
  return response;
};

// export const updateExamStatus = async payload => {
//   const response = await Api.post(`${baseUrl}/quiz/status`,payload);
//   return response;
// };

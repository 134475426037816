import styled from 'styled-components';

export const UiHomeworkCardEdit = styled.div`
	display: block;
`;

export const UiCardHeader = styled.div`
	display: block;
	margin: 0 1.5rem;
	font-size: 2rem;
	border-bottom: 2px solid #ccc;
`;

export const UiButtonBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 0 32px;
	width: 100%;

	& > * {
		margin: 0 4px;
	}
`;

export const UiExistFileBox = styled.div`
	padding: 8px;
	border: 1px solid #d0e0e2;
	border-radius: 5px;

	> span {
		font-size: 18px;
		font-weight: bold;
	}

	> .item {
		display: flex;
		align-items: center;
		padding: 4px 0 8px;
		width: max-content;

		> span {
			cursor: pointer;
		}

		> svg {
			margin-left: 4px;
		}
	}
`;

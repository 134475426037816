import styled from 'styled-components';

export const UiCheckbox = styled.div`
	position: relative;
	display: flex;
`;

export const UiRemoveLine = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: ${({ showLine }) => showLine ? 'block' : 'none'};
	margin: auto;
	width: 100%;
	height: 1px;
	background-color: #8b90a0;
`;

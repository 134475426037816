/* 取得 url path 最後一個 */
const popPathList = (pathList, count) => {
  const pathName = pathList.pop();

  if (count === 1) {
    return pathName;
  } else {
    return `${popPathList(pathList, count - 1)}/${pathName}`;
  }
};

/* 取得頁面名 */
export const getPageName = (path = '', count = 1) => {
  const pathList = path.split('/');
  return popPathList(pathList, count);
};

export const convertArrayToMap = (arr, key) =>
  arr ? arr.reduce((acc, v) => {
    if (v[key]) {
      acc[v[key]] = v;
    }
    return acc;
  }, {}) : {};

export const convertArrayToMapById = (arr) => convertArrayToMap(arr, 'id');

export const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));

export const groupBy = (arr, key) => {
  return arr ? arr.reduce((groups, item) => {
    const val = item[key] ? item[key] : 'school';
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {}) : {};
};

export const subtractionById = (a, b) => {
  const aMap = convertArrayToMapById(a);
  const bMap = convertArrayToMapById(b);
  return Object.keys(aMap).filter(key => !bMap[key]).map(key => aMap[key]);
};

export const intersectionById = (a, b) => {
  const aMap = convertArrayToMapById(a);
  const bMap = convertArrayToMapById(b);
  return Object.keys(aMap).filter(key => bMap[key]).map(key => aMap[key]);
};

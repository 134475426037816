import styled from 'styled-components';

export const UiAlbumCard = styled.div`
	display: inline-flex;
	margin: 0.5rem 0;
	max-width: 300px;
	box-sizing: border-box;
`;

export const UiLine = styled.div`
	width: 100%;
	height: 1px;
	background-color: #d0e0e2;
`;

export const UiAlbumContent = styled.div`
padding: 5px;
`;

export const UiAlbumTitle = styled.div`
	word-wrap: break-word;
	font-size: 16px;
`;

export const UiAlbumFooter = styled.div`
	display: flex;
	justify-content: space-between;
	min-height: 33.75px;
	font-size: 11px;
	color: #8b90a0;

	& svg {
		color: #a1a4b1;
	}
`;

export const UiAlbumFooterContent = styled.div`
	display: flex;
	align-self: center;
`;

export const UiAlbumPicture = styled.div`
	cursor: pointer;
`;

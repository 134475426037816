import styled from 'styled-components';

export const UiClassForm = styled.div`
	display: flex;
`;

export const UiForm = styled.form`
	flex: 3;
	display: block;
	margin: 0 auto;
`;


export const UiImageSettingButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 14px;
	width: 100%;
	color: ${({ theme }) => theme.palette.text.normal};
	background-color: ${({ theme }) => theme.palette.btn.menu};
	border-radius: 15px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;

	& > svg {
		margin-right: 0.5rem;
		font-size: 1.5rem;
		color: ${({ theme }) => theme.palette.text.show};
	}

	& > input {
		display: none;
	}
`;

export const UiSettingImage = styled.div`
	overflow: hidden;
	width: 100%;
	font-size: 0;
	margin-bottom: 1.5rem;

	& > div {
		width: 100%;
	}

	& img {
		max-width: 100%;
		border-radius: 5px;
	}
`;

export const UiFilter = styled.div`
	display: flex;
	align-items: center;

	> * {
		margin-right: 5px;
		height: max-content;
	}
`;

export const UiSearchInput = styled.div`
	width: 230px;
`;

import styled from 'styled-components';

export const UiHeader = styled.div`
	width: 100%;
`;

export const UiMain = styled.div`
	display: flex;
`;

export const UiAside = styled.div`
	flex: 1;
`;

export const UiContent = styled.div`
	flex: 9;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	& > div {
		display: flex;
		height: 100%;
		flex-direction: column;
	}
`;

import styled from 'styled-components';

export const UiCustomerService = styled.div`
	display: block;
	margin: auto;
	max-width: 700px;
`;

export const UiCustomerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin: 6rem 0;
	padding: 1rem 2rem 4rem;
	min-width: 300px;
	min-height: 620px;
	background-color: #fff;
	border: 1px solid #d0e0e2;
	border-radius: 4px;
	box-shadow: 1px 1px 10px #ccc;
	flex-wrap: wrap;

	& > div:nth-child(1) {
		width: 100%;
	}

	button {
		margin-top: 1rem;
		width: 100%;
		max-width: 350px;
		height: 40px;
	}
`;

export const UiTitle = styled.h2`
	padding: 1rem;
	width: 100%;
	font-size: 2rem;
	text-align: center;
	border-bottom: 1px solid #d0e0e2;
`;

export const UiInputBox = styled.div`
	display: flex;
	justify-content: center;
	padding: 0.5rem 0;
	width: 100%;
	max-width: 350px;

	& > span {
		display: inline-block;
		font-size: 1.2rem;
		color: ${({ theme }) => theme.palette.text.sub};
		margin-bottom: 10px;
	}

	& > div {
		width: 100%;
		height: 40px;
	}

	svg {
		color: #ccc;
	}
`;

export const UiErrorMessage = styled.div`
		padding-top: 1rem;
		color: #f00;
		letter-spacing: 2px;
`;

export const UiPrompt = styled.div`
	padding: 1rem 0;
	font-size: 1.5rem;
	text-align: center;
`;

import { format, getTime } from 'date-fns';

export const useCalTime = () => {
  const calTime = (date) => {
    const limit = (getTime(new Date()) - getTime(new Date(date))) / 1000;
    let content = '';
    if (limit < 60) {
      content = '剛剛';
    } else if (limit >= 60 && limit < 3600) {
      content = Math.floor(limit / 60) + '分鐘前';
    } else if (limit >= 3600 && limit < 86400) {
      content = Math.floor(limit / 3600) + '小時前';
    } else {
      content = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    }
    return content;
  };
  return [calTime];
};

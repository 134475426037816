import styled from 'styled-components';

export const UiMySessions = styled.div`
	position: relative;

	/* display: flex; */

	/* justify-content: center; */
	margin-top: 4rem;
	padding: 1rem;
	flex: 1;
	flex-wrap: wrap;

	& > div {
		display: inline-block;
		margin-bottom: 3rem;
		width: 23%;
		max-width: 300px;

		${({ theme: { media } }) => media.notebook`
      width:31%;
		`}

		${({ theme: { media } }) => media.pad`
      width: 48%;
		`}
	}

	& > div:not(:nth-child(4n)) {
		margin-right: calc(8% / 3);
		${({ theme: { media } }) => media.notebook`
      margin-right: 0;
  `}
	}

	${({ theme: { media } }) => media.notebook`
    & > div:not(:nth-child(3n)) {
      margin-right: 2%;
    }
  `}

	${({ theme: { media } }) => media.pad`
    & > div:not(:nth-child(3n)) {
      margin-right: 0;
    }
    & > div:not(:nth-child(2n)) {
      margin-right: 4%;
    }
  `}

`;

export const UiTrack = styled.div`
	/* position: fixed;
	top: calc(75px + 1rem);
	left: 2%; */
	margin-top: 4rem;
	width: 200px;
	height: 100%;
`;

export const UiSessionTab = styled.ul`
	display: block;
	margin: 1rem auto;
	padding-left: 0;
	width: 100%;
`;

export const UiSessionTabItem = styled.button`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 1rem 0.75rem;
	max-height: 52.5px;
	font-size: 1rem;
	color: ${({ theme }) => theme.palette.primary.sec};
	background-color: ${({ theme, active }) => active ? theme.palette.tab.highlight : '#fff'};
	border: 1px solid #d8edf0;
	transition: 0.5s;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme, active }) => active ? theme.palette.tab.highlighthover : '#f4f3f0'};
	}

	& > svg {
		margin-right: 1rem;
		font-size: 1.5rem;
		color: ${({ theme, active }) => active ? '#fff' : theme.palette.tab.highlight};
	}

	& + div {
		margin-top: 0.5rem;
	}
`;

export const UiButtonBox = styled.div`
	display: flex;
	justify-content: center;
	padding: 1rem 0;
	margin-bottom: 2rem;
	width: 100%;
`;

export const UiCenterBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 !important;
	padding: 0;
	width: 100% !important;
	max-width: 100% !important;
	height: 100%;
	font-size: 2rem;
	text-align: center;
	color: #f38a04;

	& > span {
		display: inline-block;
		width: 100%;
	}
`;

export const UiActionBox = styled.div`
	padding-top: 1rem;
`;

export const UiMainContent = styled.div`
	width: 100%;
`;

import styled from 'styled-components';

export const UiHomeInvitationTable = styled.div`
	display: block;
`;

export const UiTableRow = styled.div`
	margin: 1rem 0;

	table > tbody > tr > td:last-child {
		width: 40%;
	}
`;

import styled from 'styled-components';

export const UiHomeMemberTable = styled.div`
	display: block;

	> h4 {
		margin-top: 0;
		font-size: 20px;
		font-weight: 100;
		color: #242c3f;
		line-height: 30px;
	}
`;
